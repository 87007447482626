#contractors {
    .hero .subtitle {
        font-size: 20px;
    }
    .hero h1 {
        font-size: 44px;
        
        @include small-desktop {
            font-size: 40px;
        }
        @include tablet {
            font-size: 38px;
        }
        @include mobile {
            font-size: 34px;
        }
        @include small-mobile {
            font-size: 32px;
        }
    }
    .standards {
        padding: 64px 0;

        h4 {
            font-size: 20px;
            margin-top: 35px;
        }

        .accordion-button {
            padding: 20px 0;
            
            .fa-plus {
                position: relative;
                top: 3px;
            }
        }
        .accordion-content {
            .textBlock {
                padding: 32px 0;
            }
            strong {
                color: $white;
            }
        }
    }
    #submission {
        .internalForm {
            .freeform-row .freeform-column .input-group-one-line label {
                text-transform: none;
                font-weight: 500;
            }
            .freeform-row .freeform-column .input-group-one-line label {
                position: relative;
                top: 1px;
            }
        }
        .externalForm {
            text-align: center;
            
            .button {
                margin-top: 20px;
            }
        }
    }
}