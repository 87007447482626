.utilityBar {
    border-bottom: solid 1px $greyD;
    padding: 20px;
    
    h5 {
        color: $greyA;
        font-size: 14px;
        position: relative;
        top: 5px;
        
        @include touch {
            top: 0;
        }
    }
    i {
        color: $greyA;
    }
}