.accentHero {
    position: relative;
    padding: 200px 0;
    @extend .backgroundSettings;
    
    @include tablet {
        padding: 150px 0;
    }
    @include handheld {
        padding: 100px 0;
    }
    
    @include screen {
        background-attachment: fixed;   
    }
    
    .container {
        position: relative;
        z-index: 20;
    }
    h3 {
        font-family: $titlingBold;
        color: $white!important;
    }
    p {
        font-weight: 600;
        color: $white!important;
    }
    .primary {
        margin-top: 30px;
        color: $white!important;
        
        &:hover {
            color: $blue!important;
        }
    }
    &.noLinks {
        .textBlock {
            @include screen {
                width: 70%;
                margin: 0 auto;
            }
            @include desktop {
                width: 75%;
                margin: 0 auto;
            }
            @include small-desktop {
                width: 80%;
                margin: 0 auto;
            }
        }
    }
    &.hasLinks {
        .textBlock {
            width: 100%;
            
            @include screen {
                width: 85%;
            }
        }
    }
    .links {
        @include touch {
            margin-top: 30px;
        }
        li {
            margin-bottom: 20px;
            
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        &.kbsWarm {
            .accent {
                background: rgba(255, 79, 1, 0.15);
                
                &.hasLink {
                    cursor: pointer;
                    &:hover {
                        background: rgba(255, 79, 1, 0.5);
                    }
                }
            }
        }
        &.kbsCool {
            .accent {
                background: rgba(5, 15, 80, 0.15);
                
                &.hasLink {
                    cursor: pointer;
                    &:hover {
                        background: rgba(5, 15, 80, 0.5);
                    }
                }
            }
        }
    }
    &.left {
        text-align: left;
    }
    &.center {
        text-align: center;
    }
}