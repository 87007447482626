.imageBlock {
    img {
        display: block;
        
        &.heroImage {
            width: 100%!important;
        }
        &.flushImage {
            width: 100%!important;
        }
        &.standardImage {
            max-width: 75%;
            margin: 0 auto;
        }
        &.center {
            margin: 0 auto;
        }
        &.left {
            margin: 0;
        }
        &.centerOffset {
            margin: 0 auto;
            max-width: 60%;
        }
    }
}