input {
    border: solid 1px $greyE;
    font-family: $body;
    padding: 15px;
    border-radius: 0;
    background-clip: padding-box;
    box-shadow: none !important;
    
    &.input {
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $white;
        }
        ::-moz-placeholder { /* Firefox 19+ */
            color: $white;
        }
        :-ms-input-placeholder { /* IE 10+ */
            color: $white;
        }
        :-moz-placeholder { /* Firefox 18- */
            color: $white;
        }
        &::placeholder {
            color: $white;
        }
    }
    @extend .smooth;
    
    &[type=submit] {
        background: $blue;
        color: $white;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        
        &:hover {
            background: $green;
        }
    }
}
label {
    color: $blue;
    font-size: .9em;
    font-weight: 400;
    letter-spacing: $spaceMd;
    margin-bottom: 10px;
    text-transform: uppercase;
}
select {
    border: solid 1px $greyE;
    font-family: $body;
    padding: 15px;
    border-radius: 0;
}
button[type=submit] {
    border: none;
    font-family: $body;
    background: $blue;
    color: $white;
    font-weight: 600;
    padding: 15px 45px;
    text-transform: uppercase;
    cursor: pointer;
    @extend .smooth;
    
    &:hover {
        background: $green;
    }
}
textarea { 
    @extend input;
    height: 120px;
    resize: none;
}

@include handheld() {
    select {
        appearance: none;
        background: #fff url('../../img/dropdown-arrow.svg') right no-repeat;
        background-position-x: calc(100% - .5rem);
        color: $black;
    }
}

/*-------------------------
    THEMING
--------------------------*/ 
.bg-darkBlue {
    label {
        color: $greyE;
    }
}


/*-------------------------
    FREEFORM OVERRIDES 
--------------------------*/ 
.freeform-form-has-errors {
    background: $red;
    padding: 10px;
    color: $white!important;
    font-weight: 600;
    margin-bottom: 10px;
    border-radius: $radius;
}
.freeform-row .freeform-column .freeform-errors>li, .freeform-row .freeform-column .ff-errors>li {
    color: $red;
}
.errors freeform-errors {
    li {
        color: $red;
        font-size: .9em;
    }
}
.freeform-row  {
    strong {
        color: inherit;
    }
    @include touch {
        display: block!important;
    }
    .freeform-column  {
        .freeform-label {
            font-weight: 500!important;
        }

        label.freeform-input-only-label {
            align-items: center;
            display: flex;
            position: relative;

            @include handheld() {
                input[type=checkbox], input[type=radio] {
                    transform: scale(.7);
                }
            }
        }

        .freeform-label + label, .freeform-label + label + label, .input-group-one-line label {
            display: flex;
            align-items: center;

            @include handheld() {
                input[type=radio] {
                    transform: scale(.7);
                }
            }
        }
    }
}
.freeform-signature-wrapper {
    canvas {
        max-width: 100%;
    }
}

/*-------------------------
    HUBSPOT OVERRIDES 
--------------------------*/
.hubSpotWrapper {
    fieldset {
        display: block;
    }
    .field {
        margin-bottom: 25px!important;
    }
    label {
        font-weight: 500;
        display: block;
        
        .hs-form-required {
            color: $red;
            margin-left: 3px;
        }
    }
    .hs-input {
        width: 100%;
    }
    .input {
        border-radius: 0;
        background: none;
        border: none;
        padding: 0;
        border-radius: 0;
        height: auto;
        margin: 0;
        width: 97%;
        box-shadow: none;
        
        input {
            border-radius: 0;
        }
        textarea {
            width: 100%!important;
        }
    }
    .hs-form-checkbox {
        span {
            text-transform: none;
            padding-left: 6px;
            position: relative;
            top: -1px;
        }
        input {
            border-radius: 0;
            max-height: 15px;
            max-width: 15px;
            @supports (-webkit-touch-callout: none) {
                height: 15px !important;
                width: 15px !important; 
                max-height: 15px !important;
                max-width: 15px !important;
                padding: 0 !important;
            }
        }
    }
    .form-columns-1 {
        max-width: none;
        //margin-bottom: 15px;
    }
    .form-columns-2 {
        max-width: none;
        //margin-bottom: 15px;
    }
    .form-columns-3 {
        max-width: none;
        //margin-bottom: 15px;
    }
    textarea {
        resize: none!important;
    }
    .actions {
        margin-top: 15px;
    }
    .hs-button {
        background: $blue!important;
        border-color: $blue!important;
        padding: 15px 45px;
        -webkit-appearance: none;
        
        &:hover {
            background: $green!important;
            border-color: $green!important;
        }
    }
    .hs_error_rollup {
        label {
            background: $red;
            padding: 10px;
            color: $white!important;
            font-weight: 600;
            text-transform: none;
            width: 97%;
        }
    }
    .hs-error-msg {
        text-transform: none;
        font-size: 14px;
        color: $red;
        margin: 4px 0 0 0;
    }
    .hs-fieldtype-checkbox {
        margin-top: 10px;
    }
}
.ff-form-errors {
    background: $red !important;
    padding: 10px !important;
    border-radius: $radius !important;
    margin-bottom: 20px;
    text-transform: none;
    p {
        color: $white!important;
        font-weight: 600;
        font-size: 1em;
    }
}
.ff-form-success {
    background: $green !important;
    padding: 10px !important;
    border-radius: $radius !important;
    margin-bottom: 20px;
    text-transform: none;
    p {
        color: $white!important;
        font-weight: 600;
        font-size: 1em;
    }
}

#contact-employeeRelations {
    label {
        &:not(#standard) {
            display: block;
            text-transform: none;
            font-weight: 500;
            color: $grey5;
        }
    }
    input[type=radio] {
        @include touch {
            transform: scale(.7)!important;
        }
    }
}