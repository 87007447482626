.statsHero {
    background-color: $blue; //fallback for missing img
    text-align: center;
    padding: 200px 0;
    @extend .backgroundSettings;
    
    .contentArea {
        h2 {
            color: $white;
            font-size: 48px;
            margin-bottom: 100px;
            
            strong {
                color: $yellow;
            }
        }
        .stats {
            li {
                margin-bottom: 80px;
                
                &:last-of-type {
                    margin-bottom: 0;
                }
                h4 {
                    color: $white;
                    font-size: 26px;
                    margin-top: 10px;
                }
                span {
                    @extend h4;
                    font-size: 60px;
                    
                    strong {
                        color: $green;
                    }
                }
            }
        }
    }
}