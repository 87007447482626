.videoWrapper {
    iframe {
        width: 100%;
        height: 400px;
        
        @include mobile {
            height: 300px;
        }
        @include small-mobile {
            height: 300px;
        }
        @include xs-mobile {
            height: unset;
        }
    }
    &.alt {
        iframe {
            width: 100%;
            height: 300px;
            
            @include xs-mobile {
                height: unset;
            }
        }
    }
}