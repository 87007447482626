#canada { //this id applies to landing, header, and footer on ca microsite
    &.canadaHeader {
        .brand img {
            width: 250px!important;

            @include small-mobile {
                width: 200px!important;
            }
        }
    }
    .hero {
        .scroll {
            display: block;
        }
    }
    .contactInfo {
        li {
            &.email {
                display: block!important;
            }
        }
    }
    .footerContent .level {
        padding: 33px 0;
    }
}
