.listGroup {
    &.wide {
        width: 100%;
        margin: 0 auto;
    }
    &.narrow {
        width: 70%;
        margin: 0 auto;
        
        @include small-desktop {
            width: 75%;
        }
        @include touch {
            width: 100%;
        }
    }
    
    .listTitle {
        background: $blue;
        width: 100%;
        text-align: center;
        padding: 10px 15px;
        margin-bottom: 40px;
        border-radius: $radius;
        
        h3 {
            font-family: $body;
            color: $white;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: $spaceMd;
            
            @include handheld {
                font-size: 13px;
            }
        }
    }
    .structuedList {
        margin-top: 30px;
        h4 {
            font-family: $body;
            font-size: 22px;
            font-weight: 700;
            border-bottom: 1px solid $red;
            padding-bottom: 20px;
            margin-bottom: 20px;
            
            @include handheld {
                font-size: 17px;
            }
        }
        ul {
            li {
                font-weight: 500;
                margin: 0 0 12px 0;
                
                a {
                    color: $blue;
                    
                    &:hover {
                        color: $green;
                    }
                }
            }
        }
    }
}