.features {
    .intro {
        margin-bottom: 60px;
        h5 { 
            color: $green;
            margin-bottom: 15px;
        }
        h3 {
            //color: $white;
            margin-bottom: 30px;
        }
    }
    img {
        margin-bottom: 60px;
    }
}