.history {
    
    .timeline {
        border-left: 5px solid $yellow;
        padding: 15px 0;
        
        @include touch {
            margin-top: 40px;
        }
        
        .milestone {
            position: relative;
            left: -5px;
            border-left: 5px solid $green;
            padding-left: 15px;
            
            &.range-none {
                border-left: 5px solid transparent;
            }
            &.range-small {
                padding-bottom: 30px;
            }
            &.range-medium {
                padding-bottom: 60px;
            }
            &.range-large {
                padding-bottom: 90px;
            }
            
            &.gap-small {
                margin-bottom: 30px;
            }
            &.gap-medium {
                margin-bottom: 60px;
            }
            &.gap-large {
                margin-bottom: 90px;
            }
            
            .year {
                font-family: $titlingMedium;
                font-size: 20px;
                color: $blue;
                
                .marker {
                    width: 22px;
                    height: 22px;
                    border-radius: $round;
                    background: $green;
                    border: 3px solid $white;
                    position: absolute;
                    left: -14px;
                }
                i {
                    font-size: 15px;
                    position: relative;
                    top: -2px;
                }
            }
            .tooltip {
                background: $blue;
                color: $white;
                padding: 10px;
                position: absolute;
                top: 25px;
                left: 15px;
                z-index: 200;
                
                p {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }
}

.bg-darkBlue {
    .history {
        .timeline {
            .milestone {
                .year {
                    color: $white;
                    
                    .marker {
                        border-color: $blue;
                    }
                }
                .tooltip {
                    background: $greyE;
                    
                    p {
                        color: $blue;
                    }
                }
            }
        }
    }
}