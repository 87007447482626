#ifs {
	.contactIfs {
		.hero {
			.subtitle, .summary, h1 {
				@include large-desktop {
                	width: 40%;
	            }
	            @include desktop {
	                width: 45%;
	            }
	            @include small-desktop {
	                width: 50%;
	            }
	            @include tablet {
	                width: 60%;
	            }
	            @include handheld {
	                width: 100%;
	            }
			}
		}
		.contact {
			margin-top: 40px;
		}
		.contactInfo {
			li, span, a, p {
				color: $blue;
			}
			a:hover {
				color: $green;
			}
			li {
				@include touch {
					text-align: left;
				}
			}
			.caps, .numbers {
				vertical-align: baseline;
			}
			.email {
				display: block!important;
			}
		}
		form {
			background-color: $white;
			padding: 32px;
			width: 100%;

			@include screen {
				position: absolute;
				top: -380px;
				z-index: 10;
			}
			@include touch {
				border: 1px solid $greyE;
			}
			@include handheld {
				padding: 24px;
			}
		}
	}
}