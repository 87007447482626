.videoGroup {
    .launchVideo .orb {
        position: absolute;
        bottom: 20px;
        left: 20px;
    }
    .imageWrapper {
        width: 100%;
        position: relative;
        @extend .backgroundSettings;
        
        &.oneUp {
            height: 375px;
            
            @include tablet {
                height: 450px;
            }
            @include small-mobile {
                height: 300px;
            }
            @include xs-mobile {
                height: 300px;
            }
        }
        &.twoUp {
            height: 350px;
            
            @include tablet {
                height: 450px;
            }
            @include small-mobile {
                height: 300px;
            }
            @include xs-mobile {
                height: 300px;
            }
        }
        &.threeUp {
            height: 275px;
            
            @include tablet {
                height: 450px;
            }
            @include small-mobile {
                height: 300px;
            }
            @include xs-mobile {
                height: 300px;
            }
        }
        
        .featImg {
            @extend .backgroundSettings;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
        }
        &:hover {
            .launchVideo .orb {
                transform: scale(.9)
            }
        }
    }
    .videoBox {
        iframe {
            position: absolute!important;
            top: 0!important;
            left: 0!important;
            width: 100%!important;
            height: 100%!important;
        }
    }
    .context {
        h4 {
            font-size: 24px;
            margin-bottom: 20px;
            margin-top: 20px;
        }
        p {
            font-size: 17px;
        }
    }
}