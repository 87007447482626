.card {
    position: relative;
    box-shadow: $shadow;
    background-color: $white;
    width: 100%;
    border-radius: 0;
    
    .launchVideo .orb {
        position: absolute;
        bottom: 20px;
        left: 20px;
    }
    
    &.stat {
        text-align: center;
        
        .accentBar {
            width: 100%;
            height: 12px;
        }
        h3 {
            color: $blue;
            font-size: 65px;
            font-family: $titlingBold;
            margin: 60px auto 50px auto;
            width: 90%;

            @include small-desktop {
                font-size: 60px;
            }
            @include tablet {
                font-size: 42px;
            }
            @include mobile {
                font-size: 60px;
            }
            @include small-mobile {
                font-size: 48px;
            }
        }
        h4 {
            color: $blue;
            font-family: $body;
            font-size: 17px;
            font-weight: 500;
            width: 90%;
            margin: 0 auto;
            padding-bottom: 30px;
        }
        &.simple {
            h3 {
                display: none;
            }
            h4 {
                @extend h3;
                color: $blue!important;
                display: block;
                margin: 0 auto;
                font-size: 25px;
                padding: 60px 0;
            }
        }
    }
    &.feature {
        text-align: left;
        box-shadow: $shadow;
        background-image: url(https://www.kbs-services.com/assets/img/chevron-grey.svg);
        background-size: 85%;
        background-position: -100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .accentBar {
            width: 100%;
            height: 10px;
            position: absolute;
            top: 0;
        }
        .headline {
            padding: 120px 60px;
            
            @include small-desktop {
                padding: 100px 40px;
            }
            @include tablet {
                padding: 100px 40px;
            }
            @include handheld {
                padding: 80px 30px;
            }
        }
        h1, h2, h3, h4, h5, h6 {
            color: $blue!important;
            font-size: 26px!important;
        }
        p {
            color: $grey;
        }
    }
    &.client {
        box-shadow: none;
    }
    &.insight {
        box-shadow: $shadow;
        margin-bottom: 25px;
        
        img {
            width: 100%;
            height: auto;
        }
        .cardContent {
            padding: 30px;
            
            .category {
                color: $grey;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: $spaceMd;
                margin: 0 0 15px 0;
            }
            h4 {
                color: $blue;
                font-family: $body;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 80px;
                line-height: 1.4em;
                letter-spacing: $spaceSm;
            }
        }
        .button.hasIcon {
            color: $blue!important;
            position: absolute;
            bottom: 30px;
            left: 30px;
            font-size: 15px;
        }
    }
    &.insightFeature {
        box-shadow: $shadow;
        position: relative;
        margin: 80px 0;
        
        @include touch {
            margin: 40px 0;
        }
        @include handheld {
            margin: 30px 0;
        }
        
        &.alt {
            margin: 0;
        }
        
        .imgWrapper {
            width: 35%;
            height: 100%;
            position: absolute;
            @extend .backgroundSettings;
            
            @include touch {
                position: relative;
                width: 100%;
                height: 400px;
            }
            @include handheld {
                position: relative;
                width: 100%;
                height: 300px;
            }
        }
        .cardContent {
            padding: 60px 40px 60px 40%;
            
            @include touch {
                padding: 30px;
            }
            
            .category {
                color: $grey;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: $spaceMd;
                margin: 0 0 10px 0;
            }
            h4 {
                color: $blue;
                font-weight: 600;
                font-size: 22px;
                font-family: $body;
                margin-bottom: 20px;
                line-height: 1.4em;
                letter-spacing: $spaceSm;
            }
            .snippet {
                font-size: 17px;
                margin-bottom: 40px;
                
                p {
                    font-size: 17px;
                    margin: 0;
                }
            }
            .button.hasIcon {
                color: $blue!important;
                font-size: 15px;
            }
        }
    }
    
    
    
    
    &.story {
        box-shadow: $shadow;
        margin-bottom: 25px;
        
        img {
            width: 100%;
            height: auto;
        }
        .cardContent {
            padding: 30px;
            
            .category {
                color: $grey;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: $spaceMd;
                margin: 0 0 15px 0;
            }
            h4 {
                color: $blue;
                font-family: $body;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 20px;
                line-height: 1.4em;
                letter-spacing: $spaceSm;
            }
            .snippet {
                margin-bottom: 60px;
                font-size: 16px;
                
                p {
                    margin: 0;
                    font-size: 16px;
                }
            }
        }
        .button.hasIcon {
            color: $blue!important;
            position: absolute;
            bottom: 30px;
            left: 30px;
            font-size: 15px;
        }
    }
    &.storyGroup {
        box-shadow: $shadow;
        position: relative;
        margin: 0 0 20px 0;
        
        .imgWrapper {
            width: 35%;
            height: 100%;
            position: absolute;
            @extend .backgroundSettings;
            
            @include touch {
                position: relative;
                width: 100%;
                height: 400px;
            }
            @include handheld {
                position: relative;
                width: 100%;
                height: 300px;
            }
        }
        .cardContent {
            padding: 60px 40px 60px 40%;
            
            @include touch {
                padding: 30px;
            }
            
            .category {
                color: $grey;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: $spaceMd;
                margin: 0 0 10px 0;
            }
            h4 {
                color: $blue;
                font-weight: 600;
                font-size: 22px;
                font-family: $body;
                margin-bottom: 20px;
                line-height: 1.4em;
                letter-spacing: $spaceSm;
            }
            .snippet {
                margin-bottom: 40px;
                font-size: 17px;
                
                p {
                    margin: 0;
                    font-size: 17px;
                }
            }
            .button.hasIcon {
                color: $blue!important;
                font-size: 15px;
            }
        }
    }
    
    
    &.serviceCard {
        width: 100%;
        height: 275px;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend .backgroundSettings;
        
        .cardContent {
            position: relative;
            z-index: 20;
            
            h3 {
                color: $white;
                font-family: $body;
                font-size: 26px;
                font-weight: 600;
                text-align: center;
                padding: 0 25px;
                @extend .smooth;
                opacity: 0;
                transform: translateY(100px);
                transition-delay: .2s;
            }
        }
        .overlay {
            opacity: 0;
        }
        
        &:hover {
            .overlay {
                opacity: .85;
            }
            h3 {
                opacity: 1;
                transform: translateY(0px);
            }
        }
        @include touch {
            .overlay {
                opacity: .85;
            }
            h3 {
                opacity: 1!important;
                transform: none!important;
                transition-delay: none!important;
            }
        }
    }
    &.modalCard {
        @extend .serviceCard;
        
        .orb {
            position: absolute;
            height: 30px;
            width: 30px;
            bottom: 10px;
            right: 10px;
            z-index: 15;
            text-align: center;
            background: rgba(5, 15, 80, 0.5);
            border: 3px solid rgba(255, 255, 255, 0.3);
            border-radius: $round;
            @extend .smooth;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: $white;
            }
        }
        &:hover {
            .orb {
                background: rgba(5, 15, 80, 0.8);
                border: 3px solid rgba(255, 255, 255, 0);
            }
        }
        .overlay {
            &.fill {
                opacity: .85;
            }
        }
        h3 {
            &.fill {
                transform: none;
                transition-delay: none;
                opacity: 1;
            }
        }
        
        &:hover {
            .overlay {
                &.fill {
                    opacity: .95;
                }
            }
        }
    }
    &.leader {
        box-shadow: none;
        background: transparent;
        
        .orb {
            position: absolute;
            height: 30px;
            width: 30px;
            bottom: 10px;
            right: 10px;
            z-index: 15;
            text-align: center;
            background: rgba(5, 15, 80, 0.5);
            border: 3px solid rgba(255, 255, 255, 0.3);
            border-radius: $round;
            @extend .smooth;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: $white;
                position: relative;
                top: 1px;
            }
        }
        &:hover {
            .orb {
                background: rgba(5, 15, 80, 0.8);
                border: 3px solid rgba(255, 255, 255, 0);
            }
        }
        .imageWrapper {
            position: relative;
            line-height: .5em;

            @include handheld {
                img {
                    width: 100%;
                }
            }
        }
        
        h4 {
            font-size: 24px;
            color: $blue;
            margin-top: 20px;
        }
        h5 {
            font-family: $titlingStandard;
            color: $green;
            font-size: 15px;
            text-transform: none;
            letter-spacing: $spaceSm;
            margin-top: 10px;
        }
    }
    &.standard {
        
        @include touch {
            margin: 20px 0;
        }
        .imageWrapper {
            position: relative;
            line-height: 0;
            
            img {
                line-height: 0;
            }
            .orb {
                width: 60px;
                height: 60px;
                border-width: 6px;
                
                i {
                    font-size: 1.3em;
                }
            }
            &:hover {
                .launchVideo .orb {
                    transform: scale(.9)
                }
            }
        }
        .cardContent {
            padding: 30px;
            
            h5 {
                color: $green;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: $spaceMd;
                margin: 0 0 10px 0;
            }
            h4 {
                color: $blue;
                font-size: 24px;
                margin-bottom: 30px;
                line-height: 1.4em;
                letter-spacing: $spaceSm;
            }
            p {
                color: $grey;
            }
            .textBlock {
                margin-top: -10px;
                margin-bottom: 30px;
            }
            .button {
                font-size: 15px;
            }
            .hasIcon {
                color: $blue!important;
            }
        }
    }
    &.plain {
        background: transparent;
        box-shadow: none;
        
        @include screen {
            padding-bottom: 10px;
        }
        @include touch {
            margin: 20px 0;
        }
        .imageWrapper {
            position: relative;
            margin-bottom: 20px;
            line-height: 0;
            
            img {
                line-height: 0;
            }
            .orb {
                width: 60px;
                height: 60px;
                border-width: 6px;
                
                i {
                    font-size: 1.3em;
                }
            }
            &:hover {
                .launchVideo .orb {
                    transform: scale(.9)
                }
            }
        }
        h5 {
            color: $green;
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: $spaceMd;
            margin: 0 0 10px 0;
            
            @include screen {
                max-width: 85%;
            }
        }
        h4 {    
            font-size: 24px;
            margin-bottom: 30px;
            letter-spacing: $spaceSm;
            
            @include screen {
                max-width: 85%;
            }
        }
        .textBlock {
            margin-top: -10px;
            margin-bottom: 30px;
        }
        .button {
            font-size: 15px;
        }
    }
    &.plainCentered {
        background: transparent;
        box-shadow: none;
        text-align: center;

        @include screen {
            padding-bottom: 10px;
        }
        @include touch {
            margin: 20px 0;
        }
        .imageWrapper {
            position: relative;
            margin-bottom: 20px;
            line-height: 0;
            
            img {
                line-height: 0;
            }
            .orb {
                width: 60px;
                height: 60px;
                border-width: 6px;
                
                i {
                    font-size: 1.3em;
                }
            }
            &:hover {
                .launchVideo .orb {
                    transform: scale(.9)
                }
            }
        }
        h5 {
            color: $green;
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: $spaceMd;
            margin: 0 auto 10px auto;
            
            @include screen {
                max-width: 85%;
            }
        }
        h4 {    
            font-size: 24px;
            letter-spacing: $spaceSm;
            margin: 0 auto 30px auto;
            
            @include screen {
                max-width: 85%;
            }
        }
        .textBlock {
            margin-top: -10px;
            margin-bottom: 30px;
        }
        .button {
            font-size: 15px;
        }
    }
    &.association {
        background: transparent;
        box-shadow: none;
        text-align: center;
        padding: 20px;
    }
    &.taskCard {
        text-align: center;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        min-height: 180px;
        background-color: $blue;
        box-shadow: none;
        
        @include handheld {
            min-height: auto;
        }
        
        h3 {
            color: $white;
            font-family: $body;
            font-size: 18px;
            font-weight: 600;
            width: 90%;
            margin: 0 auto;
            text-align: left;
            padding: 30px 0;
            
            @include handheld {
                font-size: 17px;
                width: 100%;
                padding: 25px 50px 25px 30px;
            }
        }
        .fa-arrow-right {
            color: $white;
            opacity: .5;
            position: absolute;
            right: 20px;
            bottom: 20px;
            @extend .smooth;
            
            @include handheld {
                top: 50%;
                transform: translateY(-50%);
                bottom: auto;
            }
        }
        &:hover {
            .fa-arrow-right { 
                opacity: 1;
                color: $green;
            }
        }
    }
}
.modalCards {
    .columns.is-variable .column {
        padding-top: .25rem;
        padding-bottom: .25rem;
    }
}
.services {
    .columns.is-variable .column {
        padding-top: .25rem;
        padding-bottom: .25rem;
    }
}