#ifs {
	header {
		width: 100%;
		background-color: $blue;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 30px;

		.ifsBrand {
			img {
				width: 215px;

				@include tablet {
					width: 180px;
				}
				@include handheld {
					width: 180px;
				}
			}
		}

		.hamburger {
			display: none;
			top: 35px;

			@include handheld {
				display: block;
			}
		}

		nav {
			position: absolute;
			right: 30px;
			a {
				font-weight: 600;

				@include tablet {
					font-size: 15px;
				}
			}
			@include handheld {
				display: none;
			}
			ul {
				li {
					&:last-of-type {
						margin-right: 0;
					}
				}
			}
		}

		.ifsSearch {
			@include handheld {
				display: none;
			}
		}

		.nav-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			nav {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}
		}

		.mobile-nav {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: -3px;

			.container {
				padding: 15px 30px;
			}
			.close {
				position: absolute;
				top: 32px;
				right: 32px;
			}
			.top-wrapper {
				display: flex;
				width: 100%;
				align-items: start;
				justify-content: space-between;
			}

			nav {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				margin-top: 40px;
				right: 0;
				font-size: 18px;

				ul {
					li {
						a {
							display: block;
							text-align: center;
						}
					}
				}
			}
		}
	}
}
