#home {
    .hero {
        .scroll {
            display: block;
        }
        .heroContent {
            /*h1 {
                @include large-desktop {
                    width: 40%;
                }
                @include desktop {
                    width: 50%;
                }
            }*/
            p {
                @include large-desktop {
                    width: 40%;
                }
                @include desktop {
                    width: 50%;
                }
            }
        }
    }
}