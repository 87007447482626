.searchBar {
    background: $blue;
    padding: 20px 20px 30px 20px;
    text-align: center;
    
    form {
        label {
            color: $white;
            font-weight: 500;
            font-size: .9em;
            text-align: left;
            text-transform: uppercase;
        }
        input {
            background: transparent;
            border: solid $green 1px;
            border-radius: 0;
            color: $white;
            padding: 15px;
        }
        .icon {
            color: $green;
            i {
                color: $green;
                &.fa-search {
                    transform: scale(-1, 1);
                }
            }
        }
        .select {
            display: block;
            &:not(.is-multiple):not(.is-loading)::after {
                border-color: $green;
            }
            select {
                background: transparent;
                border: solid $green 1px;
                border-radius: 0;
                color: $white;
                width: 100%;
                &:checked {
                    color: $white;
                }
                &option[value=""] {
                    color: $white;
                }
                option {
                    color: $blue;
                }
            }
        }
    }
}