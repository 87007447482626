.brandBanner {
    padding: 50px 60px;
    
    @include small-desktop {
        padding: 40px 50px;
    }
    @include tablet {
        padding: 30px;
    }
    @include handheld {
        padding: 40px 0;
    }
    
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        
        @include handheld {
            display: block;
        }
    }
    .contentImg {
        line-height: 0;
        
        &.graphic {
            max-width: 30%;
            max-height: 180px;

            @include handheld {
                max-width: auto;
                max-height: 130px;
                margin: 0 auto 25px auto;
                display: block;
                text-align: center;
            }
        }
        &.photograph {
            max-width: 30%;
            height: auto;
            
            img {
                border-radius: $radius;
                height: auto;
            }
            @include handheld {
                max-width: unset;
                width: 80%;
                
                margin: 0 auto 25px auto;
                display: block;
                text-align: center;
            }
        }
    }
    .contentArea {
        max-width: 70%;
        padding-left: 60px;
        position: relative;
        
        @include handheld {
            max-width: 100%;
            top: 0;
            padding: 0;
        }
        
        h2 {
            font-size: 24px;
            margin-bottom: 15px;
            line-height: 1.3em;
            
            @include tablet {
                font-size: 20px;
            }
            @include mobile {
                font-size: 18px;
                text-align: center;
            }
            @include small-mobile {
                font-size: 17px;
                text-align: center;
            }
            @include xs-mobile {
                font-size: 16px;
                text-align: center;
            }
        }
        .button {
            font-size: 15px!important;
            @include handheld {
                text-align: center;
                margin: 0 auto;
                display: block;
            }
        }
    }
}