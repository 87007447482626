[data-aos]{
  pointer-events:none
}

[data-aos].aos-animate{
  pointer-events:auto
}

@media screen{
  html:not(.no-js) [data-aos^=fade][data-aos^=fade]{
    opacity:0;transition-property:opacity,transform
  }
  
  html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate{
    opacity:1;transform:none
  }
  
  html:not(.no-js) [data-aos=fade-up]{
    transform:translate3d(0,100px,0)
  }
  
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom]{
    opacity:0;transition-property:opacity,transform
  }
  
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate{
    opacity:1;transform:translateZ(0) scale(1)
  }
  
  html:not(.no-js) [data-aos=zoom-in]{
    transform:scale(.6)
  }
  
  html:not(.no-js) [data-aos^=slide][data-aos^=slide]{
    transition-property:transform;visibility:hidden
  }
  
  html:not(.no-js) [data-aos^=slide][data-aos^=slide].aos-animate{
    visibility:visible;transform:translateZ(0)
  }

  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate{
    opacity:1;-webkit-transform:translateZ(0) scale(1);transform:translateZ(0) scale(1)
  }
}


[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos]{
  transition-timing-function:ease
}
[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos]{
  transition-duration:.4s
}
html:not(.no-js) [data-aos=fade-up]{-webkit-transform:translate3d(0,100px,0);transform:translate3d(0,100px,0)}
html:not(.no-js) [data-aos=zoom-in]{-webkit-transform:scale(.6);transform:scale(.6)}