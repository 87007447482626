.taskNav {
    background-color: $greyE;
    padding: 20px 0;
    text-align: center;
    
    @include handheld {
        padding: 15px 0;
    }
    
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 15px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: $spaceSm;
        
        @include handheld {
            display: block;
            margin: 15px 0;
        }
        
        a {
            color: $grey;
            
            &:hover {
                color: $blue;
            }
        }
    }
}
.taskList {
    h2 {
        font-size: 32px;
        text-align: center;
        padding-top: 80px;
        margin-bottom: 35px;
            
        @include tablet {
            font-size: 28px;
        }
        @include mobile {
            font-size: 26px;
            padding-top: 70px;
        }
        @include small-mobile {
            font-size: 24px;
            padding-top: 60px;
            margin-bottom: 30px;
        }
        @include xs-mobile {
            font-size: 23px;
            padding-top: 60px;
            margin-bottom: 30px;
        }
    }
    .taskGroup {
        .column {
            padding: var(--columnGap);
        }
    }
}