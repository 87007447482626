#ifs {
	footer {
		padding: 0;
		background-color: $greyE;

		@include screen {
			.rightCol {
				justify-content: flex-end;
			}
		}
		.footerContent {
			@include screen {
				padding: 50px 0;
			}
			@include touch {
				padding-top: 50px;
			}
			li {
				color: $blue;

				a {
					color: $blue;
				}
				span {
					color: $blue;
				}
			}
			.summary {

				@include screen {
					padding: 0;
					max-width: 550px;
				}

				img {
					margin-bottom: 25px;
					width: 220px;
				}
				h5, p {
					color: $blue;
				}
				p {
					margin-bottom: 0;;
				}
				@include touch {
                	border-bottom: 1px solid $greyA;
            	}
			}
			.contact {
				color: $blue;

				@include screen {
					padding: 0;
				}
				.contactInfo {
					a:hover {
						i {
							color: $green!important;
						}
					}
					i {
						color: $blue;
						@extend .smooth;
					}
					p {
						color: $blue;
					}
					.phone {
						margin-bottom: 0;
					}
					.email {
						display: block!important;
					}
				}
				.caps, .numbers {
					vertical-align: baseline;
				}
			}
		}
		.legal {
			border-top: 1px solid $greyA;

			.legalNav {
	            li {
	                color: $blue!important;
	                
	                a {
	                    color: $blue!important;
	                    
	                    &:hover {
	                        color: $green!important;
	                    }
	                }
	            }
	        }
		}
	}
}