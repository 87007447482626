.gallery {
    .thumbnail {
        img {
            @extend .smooth;
            
            &:hover {
                filter: contrast(1.2);
            }
        }
    }
}