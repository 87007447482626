h1,h2,h3,h4,h5,h6 {
    color: $blue;
    line-height: 1.2em;
    font-variant-numeric: lining-nums;
    font-style: normal;
    font-weight: normal;
}
h1 { 
    font-family: $titlingBold;
    font-size: 52px;
    
    @include small-desktop {
        font-size: 44px;
    }
    @include tablet {
        font-size: 42px;
    }
    @include mobile {
        font-size: 35px;
    }
    @include small-mobile {
        font-size: 32px;
    }
    @include xs-mobile {
        font-size: 30px;
    }
} 
h2 { 
    font-family: $titlingBold;
    font-size: 38px;
    
    @include small-desktop {
        font-size: 36px;
    }
    @include tablet {
        font-size: 34px;
    }
    @include mobile {
        font-size: 32px;
    }
    @include small-mobile {
        font-size: 28px;
    }
    @include xs-mobile {
        font-size: 25px;
    }
} 
h3 { 
    font-family: $titlingMedium;
    font-size: 34px;
    
    @include small-desktop {
        font-size: 32px;
    }
    @include tablet {
        font-size: 32px;
    }
    @include mobile {
        font-size: 28px;
    }
    @include small-mobile {
        font-size: 23px;
    }
    @include xs-mobile {
        font-size: 22px;
    }
} 
h4 { 
    font-family: $titlingMedium;
    font-size: 32px;
    
    @include small-desktop {
        font-size: 30px;
    }
    @include tablet {
        font-size: 30px;
    }
    @include mobile {
        font-size: 26px;
    }
    @include small-mobile {
        font-size: 20px;
    }
    @include xs-mobile {
        font-size: 20px;
    }
}
h5 { 
    font-family: $body;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: $spaceLg;
    text-transform: uppercase;
    
    @include xs-mobile {
        font-size: 15px;
    }
}
h6 { 
    font-family: $body;
    font-weight: 600;
    font-size: 16px;
    
    @include xs-mobile {
        font-size: 15px;
    }
}
p { 
    font-family: $body;
    font-size: 20px;
    line-height: 1.4em;
    
    @include mobile {
        font-size: 18px;
        line-height: 1.4em;
    }
    @include small-mobile {
        font-size: 17px;
        line-height: 1.4em;
    }
    @include xs-mobile {
        font-size: 16px;
        line-height: 1.4em;
    }
}
li {
    line-height: 1.3em;
}
sup {
    font-size: .4em;
}

//Base Links
a:link {
    color: $blue;
    text-decoration: none!important;
    @extend .smooth;
}
a:visited {
    color: $blue;
}
a:hover {
    color: $green;
}
a:active {
    color: $blue;
}