footer {
    background: $grey3;
    
    .footerContent {
        h5, p, li {
            color: $white;
        }
        h5 {
            font-family: $titlingMedium;
            font-size: 24px;
            font-weight: 400;
            letter-spacing: $spaceSm;
            text-transform: none;
        }
        a {
            color: $white;
            
            &:hover {
                color: $greyA;
            }
        }
        .level {
            padding: 24px 0;
            margin: 0;
            
            @include touch {
                padding: 40px 0 30px 0;
            }
            
            .level-left {
                @include large-desktop {
                    position: relative;
                    left: -30px;
                }
                @include desktop {
                    position: relative;
                    left: -20px;
                }
            }
            .level-right {
                @include large-desktop {
                    position: relative;
                    right: -30px;
                }
                @include desktop {
                    position: relative;
                    right: -20px;
                }
            }
            .level-left, .level-right {
                @include large-desktop {
                    width: 50%;
                    justify-content: center;
                    text-align: center;
                }
                @include desktop {
                    width: 50%;
                    justify-content: center;
                    text-align: center;
                }
                @include touch {
                    display: none;
                }
                
            }
            li {
                font-size: 14px;
                font-weight: 500;
                letter-spacing: $spaceMd;
                text-transform: uppercase;
                margin: 0 30px;
                
                @include small-desktop {
                    margin: 0 20px;
                }
            }
        }
        .brand {
            background: $grey3;
            width: 180px;
            position: absolute;
            left: 0;
            right: 0;
            top: 22px;
            margin: 0 auto;
            text-align: center;
            
            @include touch {
                position: relative;
                background: none;
                width: 100%;
                top: 0;
            }
            
            img {
                width: 120px;
                height: auto;
                margin: 0 auto;
            }
        }
        hr {
            height: 1px;
            background-color: $grey5;
            margin: 0;
            
            @include touch {
                display: none;
            }
        }
        .summary {
            padding: 80px 60px;
            max-width: 700px;
            
            @include touch {
                padding: 0 0 40px 0;
                margin-bottom: 30px;
                border-bottom: 1px solid $grey5;
            }
            @include handheld {
                text-align: center;
            }
            
            h5 {
                font-size: 22px;
            }
            p {
                font-size: 17px;
                margin: 20px 0;
            }
        }
        .is-narrow {
            padding: 90px .75rem 40px .75rem;
            
            .divi {
                width: 1px;
                background: $grey5;
                display: block;
                margin: 0 auto;
                padding: 50px 0 30px 0;
            }
        }
        .align {
            @include screen {
                justify-content: center;
                align-items: center;
            }
        }
        .contact {
            padding: 80px 60px;
            
            @include touch {
                padding: 0;
                margin-bottom: 50px;
            }
            @include handheld {
                width: 100%;
            }
            .social {
                margin-top: 30px;
            }
        }
    }
    .legal {
        border-top: 1px solid $grey5;
        text-align: center;
        padding: 20px;
        
        //BE Styles
        .be-related-link-container {
            font-family: monospace;
            font-size: 12px;
            text-transform: uppercase;
            padding: 15px 0 0 0;
            
            .be-label {
                color: $greyE;
                margin-right: 15px;
                display: inline-block;
                
                @include handheld {
                    display: block;
                    margin: 0 0 10px 0;
                }
            }
            .be-list {
                display: inline-block;
                
                @include handheld {
                    display: block;
                }
                
                li {
                    display: inline-block;
                    margin: 0 10px;
                    
                    @include handheld {
                        display: block;
                        margin: 15px 0;
                    }
                }
                a {
                    color: rgba(255, 255, 255, .5);
                    @extend .smooth;
                    
                    &:hover {
                        color: rgba(255, 255, 255, 1);
                    }
                }
            }
        }
        .legalNav {
            li {
                font-family: monospace;
                font-size: 12px;
                color: $greyE;
                text-transform: uppercase;
                display: inline-block;
                margin: 0 15px;
                
                a {
                    color: $greyE;
                    
                    &:hover {
                        color: $white;
                    }
                }
            }
            .copyright {
                @include handheld {
                    display: block;
                    width: 100%;
                    margin: 0 auto 10px auto;
                }
            }
        }
    }
}