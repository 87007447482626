.hero {
    position: relative;
    background-color: $blue;
    padding-top: 100px; //account for nav
    
    @include small-desktop {
        padding-top: 140px;
    }
    @include tablet {
        padding-top: 140px;
    }
    @include handheld {
        padding-top: 90px;
    }
    
    .heroContent {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 10;
        
        .subtitle, span, h1, p {
            color: $white;
            
            strong {
                color: $white;
            }
        }
        .subtitle {
            @extend h5;
            font-family: $titlingMedium;
            font-size: 22px;
            margin-bottom: 15px;
            text-transform: none;
            letter-spacing: .01em;
            
            @include mobile {
                font-size: 16px;
            }
            @include small-mobile {
                font-size: 15px;
            }
            @include xs-mobile {
                font-size: 14px;
            }
        }
        h1 {
            margin: 5px 0 25px 0;
            
            @include large-desktop {
                width: 65%;
            }
            @include desktop {
                width: 70%;
            }
            @include small-desktop {
                width: 75%;
            }
            @include tablet {
                width: 85%;
            }
        }
        p {
            font-size: 22px;
            font-weight: 600;
            
            @include screen {
                width: 55%;
            }
            @include small-desktop {
                font-size: 20px;
                width: 70%;
            }
            @include tablet {
                font-size: 20px;
                width: 85%;
            }
            @include handheld {
                font-size: 18px;
            }
        }
        .button {
            margin-top: 30px;
        }
    }
    .scroll {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -16px;
        margin: 0 auto;
        background: $white;
        border-radius: $round;
        padding: 6px;
        width: 32px;
        height: 32px;
        box-shadow: $shadow;
        z-index: 20;
        @extend .smooth;
        
        i {
            color: $blue;
            @extend .smooth;
        }
        &:hover {
            transform: scale(1.1);
            
            i {
                color: $green;
            }
        }
    }
    
    &.hasImg {
        .overlay {
            opacity: .85;
        }
    }
    &.large {
        height: 85vh;
        min-height: 675px!important;
        max-height: 900px;
        
        @include handheld {
            min-height: 550px!important;
        }
    }
    &.standard {
        height: 65vh;
        min-height: 600px!important;
        max-height: 800px;
        
        @include handheld {
            min-height: 500px!important;
        }
    }
    &.small {
        height: 50vh;
        min-height: 550px!important;
        max-height: 750px;
        
        @include handheld {
            min-height: 450px!important;
        }
    }
    &.xs {
        height: 40vh;
        min-height: 450px!important;
        max-height: 500px;
        
        @include handheld {
            min-height: 400px!important;
        }
    }
    &.tiny {
        height: 35vh;
        min-height: 420px!important;
        max-height: 480px;
        
        @include handheld {
            min-height: 280px!important;
        }
    }
    &.insight {
        height: 50vh;
        min-height: 450px!important;
        max-height: 600px;
        
        @include handheld {
            min-height: 300px!important;
            max-height: 400px;
        }
    }
    &.story {
        height: 60vh;
        min-height: 500px!important;
        max-height: 650px;
        justify-content: center;
        align-items: center;
        
        @include small-desktop {
            min-height: 600px!important;
            max-height: 700px;
        }
        @include tablet {
            min-height: 550px!important;
        }
        @include handheld {
            min-height: 550px!important;
            max-height: 750px;
        }
    }
    &.simpleHeader {
        background-color: $blue;
        height: auto;
        padding-top: 120px;
        padding-bottom: 50px;
        
        @include touch {
            padding-top: 120px;
            padding-bottom: 40px;
        }
        @include handheld {
            padding-top: 110px;
            padding-bottom: 40px;
        }
        
        .heroContent {
            h1 {
                font-size: 42px;
                margin: 0 0 15px 0;
                
                @include small-desktop {
                    font-size: 38px;
                }
                @include tablet {
                    font-size: 34px;
                }
                @include mobile {
                    font-size: 30px;
                }
                @include small-mobile {
                    font-size: 26px;
                }
                @include xs-mobile {
                    font-size: 24px;
                }
            }
            p {
                font-size: 20px;

                @include small-desktop {
                    font-size: 20px;
                }
                @include tablet {
                    font-size: 18px;
                }
                @include handheld {
                    font-size: 16px;
                }
            }
        }
    }
    &.leftAlign {
        .heroContent {
            text-align: left;
        }
    }
    &.center {
        .heroContent {
            text-align: center;
            
            h1, p {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}