.svGroup {
    display: block;
    /*display: flex;
    flex-wrap: wrap;
    
    @include touch {
        display: block;
    }*/

    .container:last-of-type {
        .splitView {
            &.kbsCustom {
                margin-bottom: 0!important;
            }
            &.float {
                margin-bottom: 0!important;
            }
            &.floatCompact {
                margin-bottom: 0!important;
            }
        }   
    }
}
.halfWidth {
    @include screen {
        width: 50vw;
        float: left;
    }
}
.svWrapper {
    display: flex;
    margin: 0 auto;
    
    @include touch {
        display: block;
    }
}

.splitView {
    display: flex;
    
    @include touch {
        display: block;
    }
    
    p { 
        margin: 30px 0 30px 0;
    }
    
    ul { 
        margin: 30px 0;
    }
    
    .imageWrapper {
        position: relative;
        .featImg {
            @extend .backgroundSettings;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
        }
        .colorBox {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
        }
        &:hover {
            .launchVideo .orb {
                transform: scale(.9)
            }
        }
    }
    
    &.kbsCustom {
        max-width: 1300px;
        align-items: center;
        margin: 0 auto 100px auto;
        background-color: transparent!important;
        
        @include screen {
            position: relative;
            top: 10px;
        }
        @include handheld {
            margin: 0 auto 80px auto;
        }
        
        .imageWrapper {
            width: 340px;
            height: 340px;
            float: left;
            position: relative;
            
            @include touch {
                margin: 0 auto 60px auto;
                float: none;
            }
            @include small-mobile {
                width: 300px;
                height: 300px;
            }
            @include xs-mobile {
                width: 260px;
                height: 260px;
            }
        }
        .sectionContent {
            width: calc(100% - 340px);
            float: left;
            
            @include touch {
                width: 100%;
                float: none;
            }
        }
        .featImg {
            border-radius: $radius;
        }
        .colorBox {
            display: block;
            border-radius: $radius;
        }
        
        &.right {
            flex-direction: row;
            
            .sectionContent {
                padding-left: 140px;
                
                @include small-desktop {
                    padding-left: 80px;
                }
                @include tablet {
                    padding: 0 80px;
                }
                @include handheld {
                    padding-left: 0;
                }
            }
            .imageWrapper {
                right: -20px;
                top: -20px;
                
                @include handheld {
                    top: 0;
                    right: -10px;
                    left: 0;
                }
            }
            .colorBox {
                background: $kbsCool;
                top: 20px;
                bottom: -20px;
                left: -20px;
                right: 20px;
            }
        }
        &.left {
            flex-direction: row-reverse;
            
            .sectionContent {
                padding-right: 140px;
                
                @include small-desktop {
                    padding-right: 80px;
                }
                @include tablet {
                    padding: 0 80px;
                }
                @include handheld {
                    padding-right: 0;
                }
            }
            .imageWrapper {
                left: -20px;
                top: -20px;
                
                @include handheld {
                    top: 0;
                    left: -10px;
                    right: 0;
                }
            }
            .colorBox {
                background: $kbsWarm;
                top: 20px;
                bottom: -20px;
                right: -20px;
                left: 20px;
            }
        }
    }
    
    &.float {
        margin: 0 auto 100px auto;
        background-color: transparent!important;
        
        @include handheld {
            margin: 0 auto 80px auto;
        }
        
        .imageWrapper {
            width: 40%;
            float: left;
            position: relative;
            
            @include touch {
                width: 100%;
                margin: 0 auto 60px auto;
                float: none;
            }
        }
        .sectionContent {
            width: 60%;
            float: left;
            
            @include touch {
                width: 100%;
                float: none;
            }
        }
        
        &.right {
            flex-direction: row;
            
            .sectionContent {
                padding-left: 140px;
                
                @include small-desktop {
                    padding-left: 80px;
                }
                @include tablet {
                    padding: 0 80px;
                }
                @include handheld {
                    padding-left: 0;
                }
            }
        }
        &.left {
            flex-direction: row-reverse;
            
            .sectionContent {
                padding-right: 140px;
                
                @include small-desktop {
                    padding-right: 80px;
                }
                @include tablet {
                    padding: 0 80px;
                }
                @include handheld {
                    padding-right: 0;
                }
            }
        }
    }

    &.floatCompact {
        margin: 0 auto 100px auto;
        background-color: transparent!important;
        
        @include handheld {
            margin: 0 auto 80px auto;
        }
        
        .imageWrapper {
            width: 40%;
            float: left;
            position: relative;
            
            @include touch {
                width: 100%;
                margin: 0 auto 30px auto;
                float: none;
            }
        }
        .sectionContent {
            width: 60%;
            float: left;
            
            @include touch {
                width: 100%;
                float: none;
            }
        }
        p { 
            margin: 30px 0 30px 0;

            @include screen {
                font-size: 18px;
            }
        }
        
        &.right {
            flex-direction: row;
            
            .sectionContent {
                padding-left: 60px;
                
                @include small-desktop {
                    padding-left: 60px;
                }
                @include tablet {
                    padding: 0 60px;
                }
                @include handheld {
                    padding-left: 0;
                }
            }
        }
        &.left {
            flex-direction: row-reverse;
            
            .sectionContent {
                padding-right: 60px;
                
                @include small-desktop {
                    padding-right: 60px;
                }
                @include tablet {
                    padding: 0 60px;
                }
                @include handheld {
                    padding-right: 0;
                }
            }
        }
    }
    
    &.stacked {
        width: 50vw;
        float: left;
        flex-direction: column;
        
        @include touch {
            width: 100%;
            float: none;
        }
        
        .imageWrapper {
            width: 100%;
            height: 400px;
        }
        .sectionContent {
            padding: 80px;
            max-width: 650px;
            margin: 0 auto;
            
            @include small-desktop {
                padding: 60px;
            }
            @include touch {
                padding: 40px 30px;
            }
        }
        &.bg-white {
            h1, h2, h3, h4, h5, h6 {
                color: $blue;
            }
            p, li {
                color: $grey;
            }
            .button {
                &.hasIcon {
                    color: $blue!important;
                }
            }
        }
        &.bg-grey {
            h1, h2, h3, h4, h5, h6 {
                color: $blue;
            }
            p, li {
                color: $grey;
            }
            .button {
                &.hasIcon {
                    color: $blue!important;
                }
            }
        }
        &.bg-lightGreen {
            h1, h2, h3, h4, h5, h6 {
                color: $blue;
            }
            p, li {
                color: $grey;
            }
            .button {
                &.hasIcon {
                    color: $blue!important;
                }
            }
        }
        &.bg-darkBlue {
            h1, h2, h3, h4, h5, h6, p, li {
                color: $white;
            }
            .button {
                &.hasIcon {
                    color: $white!important;
                }
            }
        }
    }
    
    &.flush {
        width: 100vw;
        
        &.right {
            flex-direction: row;
        }
        &.left {
            flex-direction: row-reverse;
        }
        .imageWrapper {
            width: 40%;
            float: left;
            
            @include touch {
                width: 100%;
                height: 400px;
                float: none;
            }
        }
        .sectionContent {
            width: 60%;
            max-width: 800px;
            margin: 0 auto;
            padding: 100px 80px;
            float: left;
            
            @include small-desktop {
                padding: 80px 60px;
            }
            @include touch {
                width: 100%;
                float: none;
                padding: 40px 30px;
            }
        }
        &.bg-white {
            h1, h2, h3, h4, h5, h6 {
                color: $blue;
            }
            p, li {
                color: $grey;
            }
            .button {
                &.hasIcon {
                    color: $blue!important;
                }
            }
        }
        &.bg-grey {
            h1, h2, h3, h4, h5, h6 {
                color: $blue;
            }
            p, li {
                color: $grey;
            }
            .button {
                &.hasIcon {
                    color: $blue!important;
                }
            }
        }
        &.bg-lightGreen {
            h1, h2, h3, h4, h5, h6 {
                color: $blue;
            }
            p, li {
                color: $grey;
            }
            .button {
                &.hasIcon {
                    color: $blue!important;
                }
            }
        }
        &.bg-darkBlue {
            h1, h2, h3, h4, h5, h6, p, li {
                color: $white;
            }
            .button {
                &.hasIcon {
                    color: $white!important;
                }
            }
        }
    }
}