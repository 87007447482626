#ifs {
	.hero {
		padding-top: 0;

		@include handheld {
			display: flex;
			justify-content: center;
		}
		.overlay {
			opacity: 1;
		}
		.heroContent {
			color: $blue;

			.colorBox {
				display: none;
			}

			@include handheld {
				width: 90%;
				height: auto;
				padding: 0;

				.container {
					padding: 32px;
					background-color: $white;
					position: relative;
					z-index: 5;
				}
				.colorBox {
					display: block;
		            width: 100%;
		            height: 100%;
		            position: absolute;
		            border: 3px solid $green;
		            right: -20px;
		            bottom: -20px;
		            z-index: 3;
		        }
			}

			.subtitle, h1, p, .button, a, .caps {
				color: $blue;
			}
			.button {
				background-color: transparent;
				border: 3px solid $green;

				&:hover {
					background-color: $green;
					color: $white;
				}
			}
			p {
				width: 100%;
				@include large-desktop {
                	width: 100%;
	            }
	            @include desktop {
	                width: 100%;
	            }
	            @include small-desktop {
	                width: 100%;
	            }
	            @include tablet {
	                width: 100%;
	            }
			}
			.subtitle, .summary, h1 {
				@include large-desktop {
                width: 50%;
	            }
	            @include desktop {
	                width: 50%;
	            }
	            @include small-desktop {
	                width: 55%;
	            }
	            @include tablet {
	                width: 60%;
	            }
	            @include handheld {
	                width: 100%;
	            }
			}
		}
	}
}