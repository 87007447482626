.textBlock {
    h1,h2,h3,h4,h5,h6,p,li {
        
        strong {
            color: inherit;
        }
    }

        //@extend %block;
        
        // Inline
        li + li {
            margin-top: 0.25em;
        } 
        
        // Block
        p, dl, ol, ul, blockquote, pre, table { 
            &:not(:last-child) {
                margin-bottom: 1em;
            }
        }
        h1 {
            //font-size: 2em;
            margin-bottom: 0.5em;
            &:not(:first-child) {
                //margin-top: 1em;
            }
        }
        h2 {
            //font-size: 1.75em;
            margin-bottom: 0.5714em;
            &:not(:first-child) {
              //margin-top: 1.1428em;
            }
        }
        h3 {
            //font-size: 1.5em;
            margin-bottom: 0.6666em;
            &:not(:first-child) {
              //margin-top: 1.3333em;
            }
        }
        h4 {
            //font-size: 1.25em;
            margin-bottom: 0.8em;
        }
        h5 {
            //font-size: 1.125em;
            margin-bottom: 0.8888em;
        }
        h6 {
            //font-size: 1em;
            margin-bottom: 1em;
        }
        ol {
            //list-style-position: outside;
            //+ltr-property("margin", 2em, false);
            margin-top: 1em;
            padding-left: 30px;

            li {
                font-size: 18px;
                padding: 6px 0;
                
                @include small-mobile {
                    font-size: 17px;
                }
                @include xs-mobile {
                    font-size: 16px;
                }
            }
            li::marker {
              color: $yellow;
            }
            
            &:not([type]) {
              list-style-type: square;
            }
            &.is-lower-alpha {
                list-style-type: lower-alpha;
            }
            &.is-lower-roman {
                list-style-type: lower-roman;
            }
            &.is-upper-alpha {
                list-style-type: upper-alpha;
            }
            &.is-upper-roman {
                list-style-type: upper-roman;
            }
        }
        ul {
            list-style: square;
            //+ltr-property("margin", 2em, false);
            margin-top: 1em;
            padding-left: 30px;

            li {
                font-size: 18px;
                padding: 6px 0;
                
                @include small-mobile {
                    font-size: 17px;
                }
                @include xs-mobile {
                    font-size: 16px;
                }
            }
            li::marker {
              color: $yellow;
            }
            
            ul {
                list-style-type: disc;
                margin-top: 0.5em;
                    
                ul {
                    list-style-type: circle;
                }
            }
        } 
        figure {
            margin-left: 2em;
            margin-right: 2em;
            text-align: center;
                
            &:not(:first-child) {
                margin-top: 2em;
            }
            &:not(:last-child) {
                margin-bottom: 2em;
            }
            img {
                display: inline-block;
            }
            figcaption {
                font-style: italic;
             }
        }
    .center {
        ul {
            padding: 0;
            list-style-type: none;
        }
    }
    .centerOffset {
        ul {
            padding: 0;
            list-style-type: none;
        }
    }
}