#ifs {
	.splitText {
		a {
			margin-top: 25px;
			display: inline-block;
		}
	}
	.splitView {
		&.left {
			.colorBox {
				background: transparent;
				border: 3px solid $green;
			}
		}
		&.right {
			.colorBox {
				background: transparent;
				border: 3px solid $green;
			}
		}
	}
	.graphicText {
		img {
			max-width: 160px;
			margin: 0 auto 25px auto;

			@include tablet {
				max-width: 140px;
			}
			@include handheld {
				max-width: 120px;
			}
		}
	}
	.tabGroup {
		h3 {
			margin-bottom: 30px;
		}
		img {
			margin-bottom: 30px;
		}
		.slide {
			a {
				margin-top: 25px;
				display: inline-block;
			}
			ul {
				li::marker {
					color: $green;
				}
			}
		}
		nav {
			a {
				display: block;
				width: 100%;
				background-color: $blue;
				color: $white;
				font-family: $ifsPoppins;
				font-weight: 600;
				font-size: 18px;
				padding: 12px 15px;
				margin-bottom: 20px;

				&.active {
					background-color: $green;
				}
				&:hover {
					background-color: $green;
				}
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
	.plain {
		.imageWrapper {
			display: block;
			margin-bottom: 30px;
		}
		h3 {
			font-size: 28px;
		}
		a {
			margin-top: 25px;
			display: inline-block;
		}
	}
	.serviceCards {
		h3 {
			font-size: 22px;
			text-align: center;
			border-top: 2px solid $greyE;
			margin-bottom: 30px;
			padding-top: 30px;

			@include tablet {
				font-size: 20px;
			}
			@include handheld {
				font-size: 18px;
			}
		}
		.serviceCard {
			text-align: center;
			position: relative;
			overflow: hidden;
			height: 350px;

			@include handheld {
				height: 315px;
			}

			.cardContent {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				z-index: 10;
			}
			h4 {
				color: $blue;
				padding: 0 15px;
				position: relative;
				top: -15px;
				@extend .smooth;
                //opacity: 0;
                color: $white;
                transform: translateY(140px);
                transition-delay: .2s;

                @include screen {
                	font-size: 26px;
                }
                @include tablet {
                	font-size: 26px;
                }
                @include handheld {
                	font-size: 24px;
                }
			}
			.button {
				position: absolute;
				bottom: 30px;
				left: 0;
				right: 0;
				text-align: center;
				@extend .smooth;
                opacity: 0;
                transform: translateY(100px);
                transition-delay: .2s;
			}
			.overlay {
	            opacity: 0;
	            @extend .smooth;
	        }
	        .darkBlue {
	        	opacity: .7!important;
	        }
        
	        &:hover {
	            .overlay {
	                opacity: 1!important;
	            }
	            .darkBlue {
	            	opacity: 0!important;
	            }
	            h4 {
	                //opacity: 1;
	                transform: translateY(0px);
	                color: $blue;
	            }
	            .button {
	            	opacity: 1;
	                transform: translateY(0px);
	            }
	        }
	        @include touch {
	            .ifsCardOverlay {
	                display: none;
	            }
	            h4 {
	            	color: $white;
	                opacity: 1!important;
	                transform: none!important;
	                transition-delay: none!important;
	            }
	            .button {
	            	color: $white;
	                opacity: 1!important;
	                transform: none!important;
	                transition-delay: none!important;
	            }
	            &:hover {
	            	h4 {
	            		color: $white!important;
	            	}
	            	.overlay {
	            		opacity: 1!important;
	            	}
	            }
	        }
		}
	}
	.featureCard {
		h2 {
			margin-bottom: 25px;
		}
		.right {
			flex-direction: row-reverse;

			@include touch {
				flex-direction: row;
			}
		}
		.left {
			flex-direction: row;
		}
		.imageWrapper {
			height: 100%;
			width: 100%;
			@extend .backgroundSettings;

			@include tablet {
				height: 350px;
			}
			@include mobile {
				height: 280px;
			}
			@include small-mobile {
				height: 240px;
			}
		}
		.serviceList {
			margin-top: 25px;

			li {
				margin-bottom: 15px;
				font-weight: 500;
				display: flex;

				&:last-of-type {
					margin-bottom: 0;
				}
				span {
					position: relative;
					top: -5px;
				}
				.fa-check-circle {
					color: $green;
					margin-right: 5px;
					position: relative;
					top: -3px;
				}
			}
		}
	}
	.ctaBanner {
		background-color: $blue;
		text-align: center;
		padding: 160px 0;
		position: relative;
		
		.container {
			position: relative;
			z-index: 5;
		}
		h2 {
			color: $white;
			margin: 0 auto;

			@include screen {
				width: 50%;
			}
			@include small-desktop {
				width: 60%;
			}
			@include tablet {
				width: 70%;
			}
		}
		a {
			margin-top: 40px;
			display: inline-block;
		}
		.corner {
			position: absolute;
			width: 600px;
			z-index: 1;

			&.top {
				top: 0;
				left: 0;
			}
			&.bottom {
				bottom: 0;
				right: 0;
			}
		}
	}
	.frameWrapper {
		
	}
}