#careers {

.exploreCareers {
  padding: 100px 0;

    @include tablet {
        padding: 60px 0 30px 0;
    }
    @include handheld {
        padding: 100px 0 30px 0;
    }

    .container {
        @media screen and (min-width: 1024px) and (max-width: 1407px) {
            padding: inherit 20px;
        }
    }
  .searchPagination {
    border-top: none;
    margin-top: 0;

    a {
      color: $grey;
    }

    .pages .current {
      color: $green;
    }
  }
}

form#job-search {
  border-top: 6px solid $green;

  fieldset {
    background-color: $greyE;
    padding: 1rem;
  }

  label {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 6px;
    text-transform: none;
    width: 100%;
  }

  input,select {
    margin-bottom: 1.2rem;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    -webkit-appearance: none;

    @include touch {
        font-size: 16px;
    }
  }

  select {
    appearance: none;
    background: #fff url('../../img/dropdown-arrow.svg') right no-repeat;
    background-position-x: calc(100% - .5rem);
  }

  button {
    box-sizing: border-box;
    text-transform: none;
    width: 100%;
    font-size: 16px;

    &[type=reset] {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: $grey;
      font-family: $body;
      font-size: 15px;
      font-weight: 600;
      padding: 20px 45px;
      @extend .smooth;

        &:hover {
            color: $red;
        }
    }
  }

  .accordion-button {
    align-items:center;
    display:flex;
    flex-flow:row;
    justify-content:center;
    margin-bottom: .2rem;
    text-align: center;

    p {
      flex: 1;
      font-size: 1rem;
      font-weight: 600;
    }

    hr {
      background: #CCC;
      width: 25%;

      @include tablet {
        width: 35%;
      }
    }

    .fas {
      float: none;
      font-size: inherit;
      font-weight: 400;
      margin-left: 5px;
    }
  }

  #only-new {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 8px 0;

    #only-new-label-container {
      flex-grow: 1;
      height: 1rem;
      margin-right: .5rem;
      overflow: hidden;
      position: relative;
      width: 1.5rem;

      .new-indicator {
        right: -1rem;
        top: -1rem;
      }
    }

    label {
      cursor: pointer;
      margin-bottom: 0;
    }

    .light-switch {
      background-color:#AAA;
      height:1.6875rem;
      position: relative;
      width:8rem;

      span:not(.indicator) {
        background: transparent;
        color: #fff;
        font-size: .75rem;
        font-weight: 600;
        line-height: 2.25;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        width: 50%;
        z-index: 900;

        &:first-child {
          left: 0;
          top: 0;
        }

        &:last-child {
          right: 0;
          top: 0;
        }
      }
    }

    span.indicator {
      background-color: $yellow;
      height: 100%;
      left: 0;
      position: absolute;
      transition: left .2s linear;
      width: 50%;
      z-index: 500;
    }

    &.only-new span.indicator {
      left: 50%;
    }
  }

  @include screen {
    .accordion-content {
      height: auto;
    }
    .accordion-button {
      display: none;
    }
  }
}

div#job-list {
    h5 {
        margin-bottom: 1rem;

        @include handheld {
            text-align: center;
        }
    }
  .row {
    border: 1px solid $greyE;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    overflow: hidden;
    padding: 1rem;
    position: relative;

    @include handheld {
        display: block;
    }

    &:last-child {
      margin-bottom: 0;
    }


    .job-details {
      flex-grow: 1;

      h6 {
          @include screen {
              padding-right: 20px;
          }
      }
      p {
        font-size: smaller;
        font-weight: 500;
        margin-top: 4px;
      }
      .divBar {
        color: $green;
        position: relative;
        top: -1px;
        margin: 0 4px;
      }
    }

    .job-link {
      font-size: smaller;
      font-weight: 600;
      text-transform: uppercase;

      @include handheld {
        margin-top: 1rem;
      }

      span.fas {
        color: $green;
        display: inline-block;
        font-weight: 400;
        margin-left: .5rem;
        padding-right: .9375rem;
        @extend .smooth;
      }
      &:hover {
        color: $blue;
        span.fas {
            transform: translateX(3px);
            color: $green;
        }
      }
    }
  }
    .emptyResults {
        padding: 120px 0;
        p {
            font-size: 25px;
        }
    }
}

div#job-entry-details {
  h4 {
    margin-bottom: 2rem;
  }

  dl {
    margin-bottom: 2rem;

    dt {
      display: none;
    }

    dd {
      margin-bottom: .8375rem;
      span.fas {
        color: $green;
        display: inline-block;
        margin-right: .6rem;
        text-align: center;
        width: 1.25rem;
      }
    }
  }

  div.btn {
    @extend button, [type=submit];
    padding: 15px 28px;
    width: 160px;

    a {
      color: $white;
    }
  }

  .job-entry-body {
    margin: 3rem 0;
    @extend .textBlock;
    font-size: 17px;
    font-family: $body;
    font-weight: 400;
    color: $grey5;

      p {
          font-size: 17px;
          color: $grey5;
      }
      li {
        font-size: 17px;
        color: $grey5;
      }
      ul {
          margin-bottom: 1.5em;

          li {
            font-size: 17px;
          }
      }
      ol {
          margin-bottom: 1.5em;

          li {
            font-size: 17px;
          }
      }
  }
}

#jobs-nav {
  position:relative;
}

#lang-toggle {
  color: $blue;
  font-size: .9em;
  padding-right: 25px;
  position: absolute;
  right: 0;
  top: 25%;

    @include handheld {
        width: 100%;
        background: $greyE;
        padding: 15px 0;
        text-align: center;
    }

  a {
    font-weight:600;
    margin-left: 10px;
    color: $grey9;

    @include touch {
        margin: 0 10px;
    }
    &:hover {
      color: $red;
    }
    &.selected {
      color: $blue;
    }
  }
}

.lang {
  display: none;
  &.show {
    display: block;
  }
}

.actions {
  align-items: center;
  display:flex;
    
    @include handheld {
        display: block;
        
        .btn {
            margin-bottom: 30px;
            margin-right: 0px;
        }
    }

  .btn {
    margin-right: 40px;
  }

  .shareJob {
    font-size: 18px;
    .fas {
        color: $green;
        transform: rotate(180deg);
    }
    span {
        font-weight: 600;
        margin-left: 4px;
        position: relative;
        top: 1px;
    }
  }
}

.modal {
  z-index: 901;

  .modal-background {
    background-color: $blue;
    opacity: .92;
  }
  .modal-content {
    background-color: white;
    height: auto;
    width: 37.5rem;
    overflow: visible;

    ._content {
      height:85%;
      padding:3rem;

      h2 {
        color: $blue;
        margin-bottom: 20px;
      }

      @include handheld {
        height: auto;
      }
    }

    .actions {
      margin: -.5rem 0 0 !important;

      .column {
        border-top: solid 1px $greyE;
        padding: 0 !important;

        &:nth-child(2) {
          border-left: solid 1px $greyE;
          border-right: solid 1px $greyE;
        }

        @include handheld {
          border: none;
          margin-bottom: 1rem;
          text-align: center;
        }
      }

      label {
        display: none;
      }

      select {
        appearance: none;
        background: #fff url('../../img/chevron-down.svg') right no-repeat;
        background-position-x: calc(100% - .5rem);
        border: none;
        color: $blue;
        font-weight: 500;
        width: 100%;

        @include handheld {
          border: 1px solid $greyE;
          width: 70%;
        }
      }

      button {
        @extend select;
        background: #fff none;
        border: none;
        cursor: pointer;
        text-align: left;
        text-transform: uppercase;
        font-weight: 600;

        .fas {
          color: $blue;
          margin-left: 48%;
        }

        @include handheld {
          border: none;
          color: $blue;
          padding: 15px 0;
          font-weight: 600;
          text-align: center;
          text-transform: uppercase;
        }
      }

      @include handheld {
        display: block;
      }
    }

    @include handheld {
      width: 90%;
    }
  }
}

}

.new-indicator {
  width: 1.75rem;
  height: 1.75rem;
  position: absolute;
  top: -.875rem;
  right: -.875rem;
  background-color: $yellow;
  transform: rotate(45deg);
}

