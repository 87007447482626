// Utilities
.smooth {
    //transition: all $transition ease-in-out;
    transition: color $transition ease-in-out, transform $transition ease-in-out, background $transition ease-in-out, background-color $transition ease-in-out, border-color $transition ease-in-out, opacity $transition ease-in-out, filter $transition ease-in-out;
}
.backgroundSettings {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.heroImage {
    @extend .backgroundSettings;
}
.overlay {
    @extend .smooth;
    &.cover {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
    }
    &.kbsWarm {
        background: $kbsWarm;
    }
    &.kbsCool {
        background: $kbsCool;
    }
    &.subtleBlue {
        background: $subtleBlue;
    }
    &.darkBlue {
        background: $darkBlue;
    }
    &.fadedBlue {
        background: rgb(5,15,80);
        background: linear-gradient(180deg, rgba(5,15,80,0.700717787114846) 0%, rgba(5,15,80,0) 50%);
    }
    &.none {
        display: none;
    }
    &.ifsCardOverlay {
        background: $ifsCardOverlay;
    }
    &.whiteFade { 
        background: rgb(255,255,255);
        background: $whiteFade;

        @include tablet {
            background: $whiteFadeAlt;
        }
        @include handheld {
            display: none;
        }
    }
}
.bgImg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    @extend .backgroundSettings;

    @include screen {
        background-attachment: fixed;
    }
}
.hasImg {
    .overlay {
        opacity: .85;
    }
}
.chevron {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: .25;
    z-index: 5;
    background-repeat: no-repeat;

    &.single {
        background-image: url(https://www.kbs-services.com/assets/img/chevron-single.svg);
        background-position: left;
        background-size: 50%;

        @include tablet {
            background-size: 85%;
        }
        @include handheld {
            background-size: 100%;
        }
    }
    &.double {
        background-image: url(https://www.kbs-services.com/assets/img/chevron-double.svg);
        background-position: center;
        background-size: 85%;

        @include small-desktop {
            background-size: 100%;
        }
        @include tablet {
            background-size: 120%;
        }
        @include handheld {
            background-image: url(https://www.kbs-services.com/assets/img/chevron-single.svg);
            background-position: left;
        }
        @include mobile {
            background-size: 85%;
        }
        @include small-mobile {
            background-size: 100%;
        }
    }
    &.none {
        display: none;
    }
}


//Animations
.slide-in {
    animation: slideIn ease 1.5s;
    //will-change: transform;
    //transform: translateZ(0);
    
    @include handheld {
        //animation: slideIn ease .5s;
        animation: none;
    }
}
@keyframes slideIn {
    0% {
        opacity:0;
        transform: translateX(-500px)
    }
    100% {
        opacity:.25;
        transform: translateX(0px)
    }
}
//
.slide-in-alt {
    animation: slideInAlt ease 1.5s;
    //will-change: transform;
    //transform: translateZ(0);
    
    @include handheld {
        //animation: slideInAlt ease .5s;
        animation: none;
    }
}
@keyframes slideInAlt {
    0% {
        opacity:0;
        transform: translateX(-500px)
    }
    100% {
        opacity:.1;
        transform: translateX(0px)
    }
}
@keyframes slideFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideOutToLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideOutToRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}
@keyframes swingIn {
    0% {
        transform: rotateX(-15deg);
        transform-origin: top;
        //opacity: 0;
    }
    100% {
        transform: rotateX(0deg);
        transform-origin: top;
        //opacity: 1;
    }
}
.fade-in {
    animation: fadeIn ease 2s;
    //will-change: opacity;
    //transform: translateZ(0);
    
    @include handheld {
        //animation: fadeIn ease .5s;
        animation: fadeIn ease .3s;
    }
}
@keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

//Color Sets
.yellow {
    strong {
        color: $yellow!important;
    }
    .accentBar {
        background-color: $yellow;
    }
}
.orange {
    strong {
        color: $orange!important;
    }
    .accentBar {
        background-color: $orange;
    }
}
.green {
    strong {
        color: $green!important;
    }
    .accentBar {
        background-color: $green;
    }
}
.blue {
    strong {
        color: $blue!important;
    }
    .accentBar {
        background-color: $blue;
    }
}
.red {
    strong {
        color: $red!important;
    }
    .accentBar {
        background-color: $red;
    }
}
.grey {
    strong {
        color: $grey9!important;
    }
    .accentBar {
        background-color: $grey9;
    }
}
.bg-white {
    background-color: $white;
}
.bg-darkBlue {
    background-color: $blue;

    h1,h2,h3,h4,h5,h6,p,li,blockquote {
        color: $white;
    }
    .textBlock {
        a {
            color: $green;

            &:link {
                color: $green;
            }
            &:visited {
                color: $green;
            }
            &:active {
                color: $green;
            }
            &:hover {
                color: $yellow;
            }
        }
    }
    .modalContent,.modal-content {
        h1 {
            color: $blue!important;
        }
        h4 {
            color: $blue!important;
        }
        p {
            color: $grey!important;
        }
        .button {
            &.hasIcon {
                color: $blue!important;
            }
        }
    }
    .button {
        &.hasIcon {
            color: $white!important;
        }
    }
    .buttonBlock {
        .button {
            &.primary {
                background: $green!important;
                color: $white!important;
                border: 3px solid $green!important;

                &:hover {
                    background: transparent!important;
                    color: $white!important;
                }
            }
        }
    }
    .spacer .divider hr {
        background-color: $yellow;
    }
}
.bg-grey {
    background-color: $greyF;

    .spacer .divider hr {
        background-color: $greyA;
    }
}
.bg-lightGreen {
    background-color: $ltGreen;

    .spacer .divider hr {
        background-color: $green;
    }
}


//Redactor Settings
.color-yellow {
    color: $yellow;
}
.color-orange {
    color: $orange;
}
.color-green {
    color: $green;
}
.color-blue {
    color: $blue;
}
.color-red {
    color: $red;
}
.highlightBox {
    //background-color: $greyD;
    background: $kbsCool;
    color: $white!important;
    display: inline-block;
    padding: 25px 35px;
    border-radius: $radius;
}
small {
    font-size: .6em;
    opacity: .8;
}


//Contact Block
.contactInfo {
    li {
        margin-bottom: 20px;

        i {
            margin-right: 5px;
            display: inline-block;
            vertical-align: text-top;
            position: relative;
            top: 2px;
        }
        span {
            color: $white;
            font-weight: 500;
            display: inline-block;
            vertical-align: text-top;
        }
        p {
            margin: 0;
            color: $white;
            font-weight: 500;
            display: inline-block;
            vertical-align: text-top;
            font-size: 1em;
        }
        &.email {
            display: none;
        }
        
        @include handheld {
            text-align: center;
            i {
                display: block;
                margin: 0 auto 8px auto;
                
            }
        }
    }
}
footer {
    .contactInfo {
        i {
            color: $grey5;
        }
    }
}
.sidebar {
    .contactInfo {
        i {
            color: $green;
        }
    }
}

//Social
.social {
    li {
        color: $white;
        display: inline-block;
        margin-right: 8px;

        .orb {
            border-radius: $round;
            //padding: 6px;
            width: 34px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            @extend .smooth;
        }
        .fab {
            position: relative;
            top: 2px;
        }
    }
}
footer {
    .social {
        .orb {
            background: $grey5;
            i {
                color: $white;
            }
            &:hover {
                background: $grey2;
            }
        }
        @include handheld {
            text-align: center;
        }
    }
}
.hero {
    .social {
        position: absolute;
        bottom: 32px;
        right: 32px;
        z-index: 15;

        @include handheld {
            display: none;
        }

        li {
            display: block;
            margin-top: 8px;
            margin-right: 0;
        }
        .orb {
            background: rgba(255, 255, 255, 0.3);
            i {
                color: $white;
            }
            &:hover {
                background: rgba(255, 255, 255, 0.5);
            }
        }
    }
}
.sidebar {
    .social {
        .orb {
            background: transparent;
            border: 2px solid $white;
            i {
                color: $white;
            }
            &:hover {
                border-color: $green;
            }
        }
        @include handheld {
            text-align: center;
        }
    }
}


//Alignment + Columns
.left {
    text-align: left;
}
.center {
    text-align: center;
}
.centerOffset {
    text-align: center;
    column-count: 1!important;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

    @include small-desktop {
        width: 75%;
    }
    @include touch {
        width: 100%;
    }
}
.oneColumn {
    column-count: 1;
}
.twoColumn {
    @include screen {
        column-count: 2;
        column-gap: 40px;
    }
}
.threeColumn {
    @include screen {
        column-count: 3;
        column-gap: 40px;
    }
}

//Video Orb
.launchVideo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .smooth;
            
    .orb {
        background: $greyF;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 82px;
        height: 82px;
        border-radius: $round;
        border: 7px solid rgba(5, 15, 80, 0.6);
        z-index: 15;
        @extend .smooth;
    }
    i {
        font-size: 2em;
        color: $blue;
        position: relative;
        right: -2px;
        top: 0px;
    }
}