/*----BUTTONS----*/
.button {
    box-shadow: none;
    white-space: normal; //allows line breaks on long labels
    @extend .smooth;
    font-weight: 600;
    border-radius: 0;
    line-height: 1.2em;
    
    &.primary {
        background: $blue;
        font-size: 15px;
        color: $white;
        letter-spacing: $spaceSm;
        text-transform: uppercase;
        padding: 1.6em 2.5em;
        border: none;
        
        &:hover {
            background: $greyD;
            color: $blue;
        }
    }
    &.secondary {
        background: $red;
        border: 2px solid $red;
        font-size: 15px;
        color: $white;
        letter-spacing: $spaceSm;
        text-transform: uppercase;
        padding: 1.5em 2.2em;
        
        &:hover {
            background: transparent;
            color: $white;
        }
    }
    &.hasIcon {
        color: $blue;
        font-size: 16px;
        border: none;
        padding: 0;
        background: transparent;
        letter-spacing: $spaceSm;
        text-transform: uppercase;
        display: inline-block;
        text-align: left;
        height: auto;
        
        i {
            color: $green;
            font-size: 15px;
            margin-left: 3px;
            @extend .smooth;
        }
        &:hover {
            i {
                transform: translateX(3px);
            }
        }
        @include handheld {
            font-size: 15px;
        }
    }
    &.submit {
        @extend .primary;
        background: $green;
    }
    &.plain {
        background: none;
        border: none;
        padding: 0;
        text-transform: uppercase;
        letter-spacing: $spaceMd;
        height: auto;
    }
    &.accent {
        width: 100%;
        background: rgba(255, 255, 255, 0.15);
        border: 2px solid $white;
        //height: 3em;
        height: auto;
        padding-top: .8em;
        padding-bottom: .8em;
        color: $white;
        cursor: default;
    }
    &.ifsCta {
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,.35) 0%, rgba(255,255,255,0.16) 100%);
        opacity: .7;
        font-size: 15px;
        color: $white;
        letter-spacing: $spaceSm;
        text-transform: uppercase;
        padding: 1.6em 2.5em;
        border: none;

        &:hover {
            opacity: 1;
        }
    }
}