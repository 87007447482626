.spacer { 
    .divider {
        margin: 100px auto;
            
        @include mobile {
            margin: 80px auto;
        }
        @include small-mobile {
            margin: 60px auto;
        }
        hr {
            background-color: $greyD;
            margin: 0;
        }
    }
    .standard {
        width: 100%;
        height: 100px;
        display: block;
            
        @include mobile {
            height: 80px;
        }
        @include small-mobile {
            height: 60px;
        }
    }
    .large {
        width: 100%;
        height: 140px;
        display: block;
            
        @include mobile {
            height: 100px;
        }
        @include small-mobile {
            height: 80px;
        }
    }
    .small {
        width: 100%;
        height: 60px;
        display: block;
            
        @include mobile {
            height: 40px;
        }
        @include small-mobile {
            height: 40px;
        }
    }
    .tiny {
        width: 100%;
        height: 15px;
        display: block;
    }
}