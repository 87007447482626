.accordionSection {
    margin-bottom: 30px;
    
    &:last-of-type {
        margin-bottom: 0;
    }
}
.accordion-button {
    cursor: pointer;
    
    &:last-of-type {
        margin-bottom: 0;
    }
    
    h3 {
        float: left;
        width: 80%;
        font-size: 24px;
    }
    .fa-plus {
        float: right;
        font-size: 22px;
        color: $green;
        backface-visibility: hidden;
        transform: rotate(0deg);
        transform-style: preserve-3d;
        transition: transform $transition;
    }
    &.expanded {
        .fa-plus {
            transform: rotate(45deg);
        }
    }
}
.accordion-content {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height $transition;

    .contentArea {
        padding: 20px 0;
        
        .button {
            margin-top: 25px;
        }
    }
}
