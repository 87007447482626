#ifs {
	h1 { 
	    font-family: $ifsPoppins;
	    font-weight: 800;
	} 
	h2 { 
	    font-family: $ifsPoppins;
	    font-weight: 800;
	} 
	h3 { 
	    font-family: $ifsPoppins;
	    font-weight: 800;
	} 
	h4 { 
	    font-family: $ifsPoppins;
	    font-weight: 800;
	}
	h5 { 
	    font-family: $ifsPoppins;
	    font-weight: 700;
	}
	h6 { 
	    font-family: $ifsPoppins;
	    font-weight: 700;
	}
	p { 
	    font-family: $ifsPoppins;
	    font-weight: 400;
	}
	li {
		font-family: $ifsPoppins;
	}
	.button {
		font-family: $ifsPoppins;
	}
}

// Splash
#ifsSplash {
	background-color: $blue;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 80px 0;

	@include tablet {
		padding: 80px 80px;
	}

	.overlay {
		position: fixed;
	}
	.bgImg {
		position: fixed;
	}
	.lpContent {
		width: 100%;
		position: relative;
		z-index: 999;

		.brand {
			display: block;

			@include screen {
				width: 600px;
				//margin: 0 auto 140px auto;
				margin: 0 auto 80px auto;
			}
			@include small-desktop {
				width: 550px;
				//margin: 0 auto 120px auto;
				margin: 0 auto 60px auto;
			}
			@include tablet {
				width: 450px;
				//margin-bottom: 40px;
				margin: 0 auto 40px auto;
			}
			@include handheld {
				width: 280px;
				//margin-bottom: 40px;
				margin: 0 auto 40px auto;
			}
		}
		.comingSoon {
			font-weight: 700;
			color: $white;
			font-family: 'Heebo', sans-serif;
			text-align: center;

			@include touch {
				margin-bottom: 10px;
			}
		}
		/*h1, span, p, li {
			color: $white;
			font-family: 'Poppins', sans-serif;
		}
		.subtitle {
			font-size: 18px;
			font-weight: 600;
			display: block;
			margin-bottom: 10px;

			@include tablet {
				font-size: 16px;
			}
			@include handheld {
				font-size: 15px;
			}
		}
		h1 {
			font-weight: 700;

			@include touch {
				margin-bottom: 10px;
			}
		}
		p {
			font-weight: 400;
			font-size: 17px;
			line-height: 1.5em;

			@include tablet {
				font-size: 16px;
			}
			@include handheld {
				font-size: 15px;
			}
		}
		.actions {
			display: block;
			margin-top: 30px;

			li {
				display: inline-block;
				margin-right: 20px;
				font-weight: 600;

				@include touch {
					display: block;
					margin-right: 0;
					margin-bottom: 20px;
				}
				&:last-of-type {
					margin-right: 0;
					margin-bottom: 0;
				}
			}
			i {
				color: $red;
				margin-right: 6px;
			}
			a {
				color: $white;
				&:hover {
					color: $red;
				}
				i {
					color: $red;
				}
			}
		}*/
	}
}