// Resets
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: $body;
}
/*#pageContent {
    overflow-x: hidden; //gutter temp fix - creates scroll issue
}*/
#wrapper {
    max-width: 100%;
}
img {
    border: 0;
    max-width: 100%;
}
.clear {
    clear: both;
}
.container {
    @include screen {
        padding-left: 64px;
        padding-right: 64px;
    }
    @include desktop {
        padding-left: 64px;
        padding-right: 64px;
        max-width: 100%;
    }
    @include small-desktop {
        padding-left: 64px;
        padding-right: 64px;
        max-width: 100%;
    }
    @include touch {
        padding-left: 32px;
        padding-right: 32px;
        max-width: 100%;
    }
    @include xs-mobile {
        padding-left: 24px;
        padding-right: 24px;
        max-width: 100%;
    }
}

// Backward Compatibility
header, nav, main, footer, article, section, aside {
   display: block;
}

/* IE11 AOS */
@media all and (-ms-high-contrast:none) {
    *::-ms-backdrop,
    [data-aos^=fade][data-aos^=fade],[data-aos^=zoom][data-aos^=zoom]{
        opacity: 1;
    }
}

// KBS Connect Toggle
.langToggle {
    background-color: $greyE;
    color: $blue;
    font-size: 1em;
    padding: 15px 25px;
    text-align: center;
  
    .fas {
        color: $blue;
        margin: 0 6px;
    }
    .inactive {
        font-weight: 600;
        color: $grey9; 
        margin: 0 6px;
    }
    a {
        font-weight: 600;
        margin: 0 6px;
        color: $grey9;

        @include touch {
            margin: 0 10px;
        }
        &:hover {
            color: $red;
        }
        &.selected {
        color: $blue;
        }
    }
}