#error {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 100%;
    position: relative;
    
    .chevron {
        opacity: .1;
    }
    .content {
        position: relative;
        z-index: 50;
        
        .primary {
            background: $green;
            border: 2px solid $green;
            margin-top: 30px;
            
            &:hover {
                background: transparent;
                border: 2px solid $green;
                color: $white;
            }
        }
    }
}