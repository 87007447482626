.clients {
    max-width: 1200px;
    margin: 0 auto;
    
    .client {
        img {
            display: block;
            max-height: 96px;
            margin: 0 auto;
        }
    }
}