.cta {
    position: relative;
    text-align: center;
    margin-top: 85px;
    @extend .backgroundSettings;
    
    @include screen {
        background-attachment: fixed;   
    }
    @include handheld {
        margin-top: 40px;
    }
    
    &:before {
        content: " ";
        position: absolute;
        display: block;
        width: 100%;
        height: 100px;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: inherit;
        transform-origin: top left;
        transform: skew(0deg, -3deg);
    }
    
    .container {
        position: relative;
        z-index: 50;
        
        .contentArea {
            padding: 220px 0 180px 0;
            
            @include tablet {
                padding: 200px 0 140px 0;
            }
            @include handheld {
                padding: 180px 0 80px 0;
            }
            
            h3 {
                color: $white;
                font-family: $titlingBold;
                font-size: 36px;
                
                @include screen {
                    width: 60%;
                    margin: 0 auto;
                }
                @include mobile {
                    font-size: 32px;
                }
                @include small-mobile {
                    font-size: 30px;
                }
            }
            .button {
                margin-top: 40px;
            }
        }
    }
}