#insightsGroup {
    .posts {
        padding-top: 60px;
    }
    &.connect {
        .posts {
            padding-top: 60px;
            padding-bottom: 40px;
        }
    }
}

#insightsPost { 
    .heroImage {
        @extend .backgroundSettings;
    }
    .intro {
        padding-bottom: 50px;
        
        .categories {
            a {
                color: $greyA;
                
                &:hover {
                    color: $green;
                }
            }
        }
        h1 {
            font-size: 36px;
            margin: 20px 0 25px 0!important;
            
            @include tablet {
                font-size: 32px;
            }
            @include mobile {
                font-size: 28px;
            }
            @include small-mobile {
                font-size: 26px;
            }
        }
        p {
            color: $greyA;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: $spaceSm;
        }
        .share {
            display: inline-block;
            margin-right: 15px;
            
            @include handheld {
                display: block;
                margin-right: 0;
                margin-bottom: 15px;
            }
            
            li {
                display: inline-block;
                margin-right: 4px;
            }
            a {
                color: $green;
                
                &:hover {
                    color: $red;
                }
                
                i {
                    font-size: 22px;
                }
            }
        }
        .date {
            display: inline-block;
            position: relative;
            top: -3px;
            
            @include handheld {
                top: 0;
                display: block;
            }
        }
    }
    .introStory {
        width: 60%;
        padding: 0 64px;
        position: relative;
        z-index: 20;
        max-width: 1344px;
         
        @include small-desktop {
            width: 70%;
        }
        @include tablet {
            width: 80%;
            padding: 32px 32px;
        }
        @include handheld {
            width: 100%;
            padding: 32px 32px;
        }
        
        .categories {
            a {
                color: $white;
                
                &:hover {
                    color: $green;
                }
            }
            span {
                color: $green;
                font-weight: 600;
                margin: 0 8px;
            }
        }
        h1 {
            color: $white;
            font-size: 36px;
            margin: 20px 0 25px 0!important;
            
            @include tablet {
                font-size: 32px;
            }
            @include mobile {
                font-size: 28px;
            }
            @include small-mobile {
                font-size: 26px;
            }
        }
        p {
            color: $greyA;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: $spaceSm;
        }
        .share {
            display: inline-block;
            margin-right: 15px;
            
            @include handheld {
                display: block;
                margin-right: 0;
                margin-bottom: 15px;
            }
            
            li {
                display: inline-block;
                margin-right: 4px;
            }
            a {
                color: $green;
                
                &:hover {
                    color: $orange;
                }
                
                i {
                    font-size: 22px;
                }
            }
        }
        .date {
            display: inline-block;
            position: relative;
            top: -3px;
            border-top: 2px solid $red;
            padding-top: 8px;
            
            p {
                color: $white;
            }
            
            @include handheld {
                top: 0;
                //display: block;
            }
        }
    }
    .author {
        padding-bottom: 50px;
        
        .headshot {
            width: 70px;
            height: 70px;
            @extend .backgroundSettings;
            border-radius: $round;
            display: inline-block;
            margin-right: 15px;
            vertical-align: middle;
            
            @include small-mobile {
                width: 60px;
                height: 60px;
                /*display: block;
                margin-right: 0;
                margin-bottom: 10px;*/
            }
        }
        .byline {
            display: inline-block;
            vertical-align: middle;
            
            /*@include small-mobile {
                display: block;
            }*/
            
            .name {
                color: $blue;
                font-weight: 600;
                font-size: 17px;
            }
            .job {
                font-size: 17px;
            }   
        }
    }
    
    .mainContent {
        padding-top: 80px;
        padding-bottom: 60px;
        width: 60%;
        margin: 0 auto;
        
        @include small-desktop {
            padding-top: 60px;
            width: 70%;
        }
        @include tablet {
            padding-top: 60px;
            width: 80%;
        }
        @include handheld {
            padding-top: 60px;
            width: 100%;
        }
    }
    
    .postContent {
        h1 { 
            font-family: $titlingBold;
            font-size: 32px;
            &:not(:first-child) {
                margin-top: 2em;
            }

            @include tablet {
                font-size: 30px;
            }
            @include mobile {
                font-size: 28px;
            }
            @include small-mobile {
                font-size: 26px;
            }
        } 
        h2 { 
            font-family: $titlingBold;
            font-size: 28px;
            &:not(:first-child) {
                margin-top: 1.5em;
            }

            @include mobile {
                font-size: 26px;
            }
            @include small-mobile {
                font-size: 24px;
            }
        } 
        h3 { 
            font-family: $titlingMedium;
            color: $blue;
            font-size: 24px;
            &:not(:first-child) {
                margin-top: 1.3em;
            }

            @include mobile {
                font-size: 22px;
            }
            @include small-mobile {
                font-size: 22px;
            }
        } 
        h4 { 
            font-family: $titlingMedium;
            color: $blue;
            font-size: 20px;
            &:not(:first-child) {
                margin-top: 1.3em;
            }

            @include mobile {
                font-size: 20px;
            }
            @include small-mobile {
                font-size: 20px;
            }
        }
        .textBlock {
            p {
                &:last-of-type {
                    margin-bottom: 0em; //was 2em
                }
            }
            a {
                font-weight: 500;
            }
        }
        .calloutText {
            background-color: rgba(250, 133, 48, 0.15);
            border: 3px solid $orange;
            border-radius: $radius;
            padding: 25px;

            @include mobile {
                padding: 20px;
            }
            @include small-mobile {
                padding: 18px;
            }

            p {
                font-size: 18px;

                @include mobile {
                    font-size: 16px;
                }
                @include small-mobile {
                    font-size: 16px;
                }
            }
        }
        .divi {
            width: 52px;
            height: 3px;
            background: $green;
            margin-bottom: 40px;
        }
        .sectionHeadline {
            @extend h3;
            color: $blue;
            margin-bottom: 25px;
            margin-top: 0!important;
        }
        .snippet {
            margin: -10px 0 25px 0;
            
            p {
                font-size: 18px;
                margin: 0;
            }
            
            @include small-mobile {
                p {
                    font-size: 15px;
                }
            }
            @include xs-mobile {
                p {
                    font-size: 14px;
                }
            }
        }
        .galleryBlock + .galleryBlock {
            margin-top: -30px;
        }
        .galleryBlock + .imageBlock {
            margin-top: -30px;
        }
        .caption {
            font-size: 15px;
            line-height: 1.2em;
            font-weight: 500;
            margin: 10px 0 0 0;
            
            @include small-mobile {
                font-size: 14px;
            }
            @include xs-mobile {
                font-size: 13px;
            }

            &.medium {
                max-width: 65%;
                    
                @include tablet {
                    max-width: 80%;
                }
                @include handheld {
                    max-width: 100%;
                }
            }
            &.small {
                max-width: 45%;
                    
                @include tablet {
                    max-width: 60%;
                }
                @include handheld {
                    max-width: 100%;
                }
            }
            
            &.lightbox {
                font-size: 15px;
                color: $grey;
            }
        }
        .imgWrapper {
            img {
                max-width: 100%;
                
                &.medium {
                    max-width: 65%;
                    
                    @include tablet {
                        max-width: 80%;
                    }
                    @include handheld {
                        max-width: 100%;
                    }
                }
                &.small {
                    max-width: 45%;
                    
                    @include tablet {
                        max-width: 60%;
                    }
                    @include handheld {
                        max-width: 100%;
                    }
                }
            }
        }
        .imgWrap {
            .mediaWrapper {
                //Legacy support
                width: 40%;
                
                @include touch {
                    width: 100%;
                    float: none;
                    margin: 0 0 30px 0;
                }
                //End legacy

                &.right {
                    margin: 0 0 0 30px;
                    float: right;
                }
                &.left {
                    margin: 0 30px 0 0;
                    float: left;
                }
                &.medium {
                    width: 40%;
                
                    @include touch {
                        width: 100%;
                        float: none;
                        margin: 0 0 30px 0;
                    }
                }
                &.small {
                    width: 25%;
                
                    @include touch {
                        width: 100%;
                        max-width: 300px;
                        float: none;
                        margin: 0 0 30px 0;
                    }
                }
            }
            .caption {
                margin-top: 15px;
                display: block;
                padding-bottom: 15px;

                @include touch {
                    padding-bottom: 0;
                }
            }
        }
        .gatedContent {
            background: $greyF;
            padding: 60px;
            
            @include touch {
                padding: 30px;
            }
            
            h2 {
                margin-bottom: 30px;
            }
            .hubspotWrapper {
                margin-top: 60px;
                
                @include touch {
                    margin-top: 30px;
                }
            }
            form {
                margin-top: 60px;
                
                @include touch {
                    margin-top: 30px;
                }
            }
            /*.primary {
                border: 3px solid $blue;
                
                &:hover {
                    color: $blue;
                    background: transparent;
                }
            }*/
        }
    }
    
    .newsletter {
        padding: 10px 0 130px 0;
        
        .newsletterContent {
            width: 50%;
            margin: 0 auto;
            position: relative;
            top: -10px;
            left: -10px;
            
            @include tablet {
                width: 65%;
            }
            @include handheld {
                width: 85%;
            }
            
            .formArea {
                background: $blue;
                padding: 60px;
                position: relative;
                z-index: 10;
                
                @include handheld {
                    padding: 30px;
                }
                
                h3 {
                    font-size: 28px;
                    text-align: center;
                    color: $white;
                    margin: 0 auto 40px auto;
                    
                    @include tablet {
                        font-size: 24px;
                    }
                    @include handheld {
                        font-size: 22px;
                    }
                }
                form {
                    margin: 0 auto;
                    text-align: center;

                    //Hubspot Form Logic
                    label {
                        color: $white!important;
                    }
                    .hs-error-msg {
                        color: $red!important;
                    }
                    .hs_error_rollup {
                        display: none!important;
                    }
                    .hs-button {
                        background: $green!important;
                        border-color: $green!important;
                        padding: 15px 45px;
                        -webkit-appearance: none;

                        &:hover {
                            background: $orange!important;
                            border-color: $orange!important;
                        }
                    }
                    
                    // Custom Form Logic
                    /*.input {
                        border: none;
                        border-radius: 0!important;
                        -webkit-appearance: none;
                        width: 50%;
                        
                        @include handheld {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                    .freeform-label {
                        display: none;
                    }
                    button[type=submit] {
                        background: $green;
                        
                        &:hover {
                            background: $red;
                        }
                    }
                    .errors {
                        li {
                            color: $white;
                            font-size: 14px;
                            font-weight: 600;
                            margin-top: 15px;
                        }
                    }*/
                }
            }
            .colorBox {
                background: $kbsWarm;
                position: absolute;
                top: 20px;
                left: 20px;
                right: -20px;
                bottom: -20px;
                z-index: 5;
            }
        }
    }
    
    .relatedContent {
        .relatedSection {
            margin-bottom: 60px;
        }
        h3 {
            font-size: 22px;
            margin: 0 0 30px 0;
        }
    }
    .moreStories {
        margin: 10px 0 20px 0;
        a {
            @extend .button;
            background: $white;
            font-size: 15px;
            color: $blue;
            letter-spacing: $spaceSm;
            text-transform: uppercase;
            padding: .4em 0;
            border-bottom: 2px solid $blue;

            &:hover {
                background: $white;
                color: $blue;
                border-bottom: 2px solid $orange;
            }
            
            @include handheld {
                font-size: 13px;
            }
        }
    }
    
    /*.relatedContent {
        h6 {
            font-weight: 600;
            letter-spacing: $spaceMd;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        ul {
            li {
                position: relative;
                border: 1px solid $greyD;
                margin-bottom: 20px;
                @extend .smooth;
                
                &:hover {
                    border-color: $yellow;
                }
                
                .imgWrapper {
                    background-color: $greyD;
                    width: 40%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    // background-image: url(https://www.kbs-services.com/assets/img/kbs-default.png);
                    @extend .backgroundSettings;
                    float: left;
                }
                .preview {
                    padding: 25px;
                    width: 60%;
                    margin-left: 40%;
                    float: left;
                    
                    span {
                        color: $green;
                        font-size: 10px;
                        text-transform: uppercase;
                        font-weight: 500;
                        letter-spacing: $spaceMd;
                        display: block;
                        margin: 0 0 8px 0;
                    }
                    p {
                        font-size: 14px;
                        color: $blue;
                        font-weight: 500;
                        line-height: 1.3em;
                        letter-spacing: $spaceSm;
                    }
                }
            }
        }
    }*/
}