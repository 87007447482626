:root {
    --nav-target-x: 10px;
}

body.nav-active {
    overflow: hidden;
    position: fixed;
}

//Mobile
header {
    .mobile-nav {
        .hamburger {
            position: absolute;
            top: 30px;
            right: 30px;

            img {
                width: 32px
            }
            @include tablet {
                top: 80px;
            }
        }

        #mobile-nav-search-form {
            .icon {
                top: 1px;
                width: 1em;
                color: $green;
            }

            #mobile-nav-search {
                background: $dkBlue;
                border: none;
                color: $white;
                font-weight: 500;
                padding-left: 1.5em;
            }
        }

        .alert {
            max-width: 75%;
            background: $red;
            position: absolute;
            left: 0;
            top: 0;
            padding: 6px 10px 6px 10px;
            color: $white;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: $spaceMd;
            text-transform: uppercase;
            text-align: center;
            z-index: 20;

            &:after {
                content: " ";
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                background: $red;
                transform-origin: bottom left;
                -ms-transform: skew(-30deg, 0deg);
                -webkit-transform: skew(-30deg, 0deg);
                transform: skew(-30deg, 0deg);
            }
        }

        .nav-wrapper {
            margin-top: 2.25rem;
            nav {
                > ul {
                    font-weight: 600;

                    > li {
                        font-size: 1rem;
                        margin-bottom: .75rem;
                    }

                    button {
                        //padding-left: 1.25rem;
                        padding-left: 1.5rem;
                        background: none;
                        border: none;
                        cursor: pointer;
                        color: $green;
                        outline: none;

                        span {
                            position: relative;
                            top: -2.5px;
                            left: -5px;
                            color: $white;
                            font-family: $body;
                            font-size: 10px;
                            font-weight: 600;
                            letter-spacing: $spaceLg;
                            text-transform: uppercase;
                            opacity: .5;
                        }
                    }

                    ul {
                        font-weight: 500;
                        text-transform: none;
                        display: none;
                        overflow: hidden;
                        transition: height .5s ease-in-out;

                        li {
                            font-size: .875rem;
                            button {
                                i.fa-chevron-down {
                                    color: $red;
                                }
                            }
                        }

                        ul li {
                            padding-left: 1.5rem;
                        }
                    }
                }

                i.fa-chevron-down {
                    font-size: 1rem;
                    height: .75rem;
                    transition: .25s ease-in-out;
                }

                li.expanded {
                    > div > button > i.fa-chevron-down {
                        transform: scaleY(-1);
                        transform-origin: center;
                    }

                    > ul {
                        display: block;
                        height: auto;
                    }
                }

                ul.collapsing {
                    display: block;
                }
            }

            @include tablet {
                height: 58vh;
                overflow: scroll;
            }
        }

        .nav-footer {
            //margin-top: 6rem;
            margin-top: 2.5rem;
            padding-bottom: 2rem;

            .button {
                background-color: $red;
                border: none;
                color: $white;
                margin-bottom: 2rem;
                padding: 1.4rem;
                text-transform: uppercase;
                width: 100%;
                font-size: .9em;
                font-weight: 700;
                letter-spacing: $spaceSm;
            }

            .social {
                .orb {
                    border: 2px solid white;
                    color: white;

                    .fab {
                        position: relative;
                        top: 1px;
                    }
                }
            }

            img {
                margin-left: auto;
                width: 60px;
            }

            @include tablet {
                margin-top: 3rem;

                .social {
                    text-align: center;
                }

                img {
                    display: none;
                }
            }
        }

        .container {
            content-visibility: auto;
            display: none;
            padding-top: 1.375rem;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            background-color: $dkBlue;
            overflow: scroll;
            z-index: 999;

            &.active {
                display: block;
            }

            .close {
                line-height: 12.5px;
                margin-left: 1rem;
            }

            .subnav {
                position: absolute;
                top: 0;
                left: 0;
                transform: translateX(100%);

                &.active {
                    display: block;
                    transform: translateX(0);
                }
            }

            @include tablet {
                left: auto;
                right: 0;
                width: 50vw;
            }
        }

        nav {
            width: 100%;
            height: auto;
            transition: transform 0.3s ease-in-out;

            &.subnav-active {
                transform: translateX(-100%);
            }

            ul {
                li {
                    display: block;
                    color: $white;
                    margin-right: 0!important;
                    opacity: 1!important;

                    a {
                        display: flex;
                        justify-content: space-between;
                        padding: 12px 0;
                    }
                    &.search {
                        a {
                            justify-content: flex-start;

                            i {
                                color: $green;
                                margin-right: 8px;
                                position: relative;
                                top: 2px;
                            }
                        }
                    }
                    &.back {
                        a {
                            color: $green!important;
                            justify-content: flex-start;

                            i {
                                margin-right: 10px;
                                position: relative;
                                top: 2px;
                            }
                        }
                    }
                }
            }
        }
    }


    //Desktop
    nav {
        ul {
            text-transform: uppercase;
            font-weight: bold;
            justify-content: space-between;

            li {
                font-size: 16px;
                letter-spacing: $spaceSm;
                display: inline-block;
                margin-right: 32px;
                @extend .smooth;

                a {
                    display: block;
                    padding: 10px 0;
                    color: $white !important;
                }
                @include small-desktop {
                    margin-right: 20px;
                }
            }
        }
        ul:hover li:not(:hover) {
            opacity: 0.5;
        }
    }

    #subnav {
        content-visibility: auto;
        perspective: 2000px;
        overflow: hidden;
        display: none;
        opacity: 0;
        transition: all 0.3s;
        //height: var(--nav-target-height);
        height: 500px;

        .header-arrow {
            position: absolute;
            top: 2px;
            left: 0;
            background-color: #fff;
            height: 20px;
            width: 20px;
            transform: translateY(20px) translateX(var(--nav-target-x)) translateX(50%) rotate(45deg);
            transition: all 0.3s;
            border-radius: 1px;
        }

        .section {
            padding: 30px!important;
        }
        .sections {
            position: relative;
            width: var(--nav-target-width);
            //min-width: 800px;
            //min-height: 400px;
            width: 70vw;
            background-color: $greyF;
            border-radius: 3px;
            margin-top: 10px;

            .item {
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                //width: 800px;
                width: 100%;
                height: 100%;
                //overflow-y: scroll;
                overflow-y: hidden;
                opacity: 0;
                transition-property: opacity, transform, height;
                transition-duration: 250ms;
                transition-timing-function: linear;
                visibility: hidden;

                &.section {
                    width: 100%;
                }

                &.current {
                    z-index: 10;
                    transform: translateX(0);
                    opacity: 1;
                    visibility: visible;
                }

                &.current > div {
                    opacity: 1;
                }

                &.left {
                    z-index: 0;
                    transform: translateX(-150px);
                }

                &.right {
                    z-index: 0;
                    transform: translateX(150px);
                }
            }

            .section.left {
                overflow: hidden;
                width: 45%;

                .nav-wrapper {
                    overflow: hidden;
                    position: relative;
                    height: 100%;
                    //overflow-y: scroll; // new for flat indistry nav
                    //height: var(--subnav-target-height);
                }

                nav {
                    width: 100%;
                    list-style: none;
                    transition: transform 0.3s linear;

                    &.active {
                        transform: translateX(-100%);
                    }

                    li {
                        a {
                            padding: 0 0 20px 0;
                            display: flex;
                            justify-content: space-between;
                            font-size: 17px;
                            font-weight: 500;
                            color: $blue;

                            i {
                                transform: translateX(-3px);
                                @extend .smooth;
                            }
                            &:hover {
                                color: $green;

                                i {
                                    transform: translateX(0px);
                                }
                            }
                        }
                        &.back {
                            a {
                                justify-content: flex-start;
                                color: $green;
                                text-transform: uppercase;
                                font-size: 13px;
                                font-weight: 600;

                                &:hover {
                                    color: $red;
                                }

                                span {
                                    position: relative;
                                    top: -4px;
                                }
                                i {
                                    margin-right: 8px;
                                    transform: translateX(0px);
                                    @extend .smooth;
                                }
                            }
                        }

                        svg {
                            height: 20px;
                        }
                    }
                }

                nav.child-nav {
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: translateX(100%);
                    transition: transform 250ms linear;
                    //height: var(--subnav-target-height);

                    &.active {
                        transform: translateX(0);
                    }

                    .back a {
                        color: $kbsCool;
                    }
                }
            }

            .section.right {
                width: 55%;
                overflow-x: hidden;
            }
        }

        .previews {
            position: relative;

            & > div {
                opacity: 0;
                transition: all 0.3s linear;
                position: absolute;
                left: 0;
                top: 0;
                //background-color: #EAEBEB;
                width: 100%;

                .imgWrapper {
                    width: 100%;
                    height: 250px;
                    @extend .backgroundSettings;

                    @include small-desktop {
                        height: 200px;
                    }
                }
                .content {
                    padding: 30px 0;

                    .previewTitle {
                        @extend h2;
                        font-size: 20px;
                        color: $blue;

                        @include small-desktop {
                            font-size: 18px;
                        }
                    }
                    p {
                        font-size: 16px;
                        margin-top: 8px;
                    }
                }
            }

            & > div.active {
                opacity: 1;
            }
        }

        &.active {
            display: flex;
            opacity: 1;

            .header-arrow {
                transform: translateY(1px) translateX(var(--nav-target-x)) translateX(50%) rotate(45deg);
            }

            .sections {
                animation: swingIn 0.25s ease-in-out both;
            }
        }
    }
}
