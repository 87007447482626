#breadcrumb {
    background: $greyE;
    border: 1px solid #4D4D4D;
    font-size: .75rem;
    width: 100%;

    ul {
        background-color: #4D4D4D;
        display: flex;
        line-height: 2.6;

        &.single {
            background: $greyE;
        }

        li {
            background: $greyE;
            color: $blue;
            display: inline-flex;
            flex-shrink: 0;
            font-weight: 700;
            letter-spacing: .03em;
            padding: .75rem 2.25rem;
            text-align: center;
            text-transform: uppercase;

            &:not(.home) {
                clip-path: polygon(calc(100% - .8rem) 0%, 100% 50%, calc(100% - .8rem) 100%, 0% 100%, .8rem 50%, 0% 0%);
                margin-left: calc(-0.8rem + 1px);
            }

            &.current {
                background-color: $grey;
                color: $white;
                padding: .75rem 2.1rem .75rem 2.25rem;
                margin-left: -.8rem;
            }

            &.home {
                clip-path: polygon(calc(100% - .8rem) 0%, 100% 50%, calc(100% - .8rem) 100%, 0% 100%, 0% 0%);
                padding: 0.75rem 1.8rem 0.75rem 1rem;
            }

            a {
                color: $grey;

                &:hover {
                    color: $blue;
                }
            }

            &:last-child {
                clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0.8rem 50%, 0% 0%);
                flex-grow: 1;
            }
        }
    }

    @include handheld {
        display: none;
    }
}
