.acsb-button {
  position: fixed;
  visibility: visible;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 1;
  line-height: 1;
  outline: none 0;
  overflow: visible;
  z-index: 999999999999;
  transition: all .15s ease;
  width: 30px;
  height: 30px;
  inset: auto 10px 10px auto;
  border-radius: 50%;
  background-color: #050f50;

  .acsb-icon {
    svg {
      position: absolute;
      max-width: 65% !important;
      max-height: 65% !important;
      overflow: visible !important;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      visibility: visible;
      opacity: 1;

      path {
        fill: #fff !important;
      }
    }
  }

  .acsb-active-icon {
    display: none;
    position: absolute;
    bottom: 0;
    left: -5px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    line-height: 1;
    border: solid 2px #050f50;
    background-color: #fff !important;

    svg {
      position: absolute;
      width: 11px;
      height: 11px;
      line-height: 1;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}