.locations {
    .linkBlock {
        width: 100%;
        border-bottom: 2px solid $greyD;
        font-size: 17px;
        font-weight: 500;
        line-height: 1.2em;
        color: $grey;
        position: relative;
        @extend .smooth;
        
        @include handheld {
            font-size: 16px;
        }
        
        &:hover {
            border-color: $yellow;
            i {
                color: $green;
            }
        }
        span {
            padding: 15px 0;
            width: 80%;
            display: inline-block;
        }
        i {
            display: inline-block;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            @extend .smooth;
        }
    }
}
.locationLead {
    .imageWrapper {
        @include large-desktop {
            width: 50%!important;
        }
    }
    .sectionContent {
        @include large-desktop {
            width: 50%!important;
        }
    }
    .contactInfo {
        list-style-type: none;
        padding: 0;
        margin: 30px 0 30px 0!important;
        
        a {
            color: $white!important;
        }
        li {
            margin-bottom: 10px;
            text-align: left!important;
            
            i {
                color: $green!important;
            }
        }
    }
    p {
        margin: 0 0 40px 0;
    }
}