[data-bg]:not(.loaded) {
  background: linear-gradient(270deg, #ffffff, #d7d7d7, #ffffff);
  background-size: 400% 400%!important;
  background-position:0% 50%;

  animation: preloaderGradient 1s ease infinite;
}

@keyframes preloaderGradient {
  0%{background-position:0% 50%;}
  50%{background-position:100% 50%;}
  100%{background-position:0% 50%;}
}