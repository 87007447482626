/*---------------
  MIXINS
---------------*/
@mixin handheld {
  @media (min-width: 0px) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}
@mixin touch {
  @media (min-width: 0px) and (max-width: #{$small-desktop-width - 1px}) {
    @content;
  }
}
@mixin screen {
  @media (min-width: #{$small-desktop-width}) {
    @content;
  }
}

@mixin xs-mobile {
  @media (max-width: #{$xsmobile-width}) {
    @content;
  }
}

@mixin small-mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$small-desktop-width - 1px}) {
    @content;
  }
}

@mixin small-desktop {
  @media (min-width: #{$small-desktop-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) and (max-width: #{$full-width}) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$full-width}) {
    @content;
  }
}