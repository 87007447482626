.sidebarSection {
    img {
        margin-bottom: 30px;
    }
    .button {
        margin-top: 15px;
    }

    .sidebarBox {
        background: $white;
        border: 1px solid $greyD;
        padding-bottom: 10px;

        @include touch {
            margin-top: 40px;
        }

        h5 {
            border-bottom: 1px solid $greyD;
            color: $blue;
            font-family: $body;
            font-size: 17px;
            font-weight: 600;
            margin: 0 0 10px;
            padding: 20px;
        }

        ul {
            list-style-type: square;
            padding-left: 30px;
        }

        li {
            padding: 10px 10px 10px 0;
            color: $grey;

            a {
                color: $grey;

                i {
                    font-size: 12px;
                    color: $yellow;
                    @extend .smooth;
                }

                &:hover {
                    color: $green;

                    i {
                        color: $green;
                        transform: translateX(2px);
                    }
                }
            }
        }
        li::marker {
            color: $yellow;
        }
    }
    .button.wide {
        width: 100%;
        padding: 2em 2.5em;
        margin-top: 0;
    }
}
