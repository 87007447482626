.glightbox-container {

/*&.glightbox-gated {
    .gclose {
      display: none;
    }
    .gslide-inline {
      padding: 80px ;
      position: relative;
      text-align: center;
      .columns {
        .column {
          padding: 0 40px;
          h2 {
            font-family: $body;
            font-size: 40px;
            font-weight: 800;
            margin-bottom: 40px;
          }
          form {
            margin-bottom: 40px;
            input {
              border: solid 1px $green;
            }
            label {
              color: $grey9;
              text-align: left;
            }
          }
          p {
            font-size: .9em;
          }
        }
      }
    }
    .modalActions {
      position: absolute;
      bottom: 0;
      left: 0;
      right:0;
      width: 100%;
      display: flex;
      a {
        &.button {
          height: auto;
        }
        &:first-child {
          border-right: solid 1px $greyE;
        }
        border: none;
        border-top: solid 1px $greyE;
        background: $white;
        display: inline-block;
        padding: 15px;
        width: 50%;
      }
    }
    .gclose {
      display: block;
      background: $white;
      position: absolute;
      top: 20px;
      right: 20px;
      height: 40px;
      width: 40px;
      svg {
        margin-top: 10px;
      }
    }
  }*/

  &.glightbox-leadership {
    .goverlay {
      background-color: $white;
      opacity: .92;
    }
    .gslide-inline {
      padding: 80px;
      @include touch {
        padding: 0;
      }
      .leadershipModal {
        .columns {
          .column {
            padding: 0 40px;
            img {
              margin-bottom: 30px;
            }
            h1 {
              font-family: $body;
              color: $blue;
              font-size: 40px;
              font-weight: 800;
              margin-bottom: 20px;
            }
            h2 {
              color: $green;
              font-family: $body;
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 40px;
            }
            p {
              padding-bottom: 20px;
              color: $grey;
            }
          }
        }
      }
    }
    .gclose {
      background: $white;
      position: absolute;
      top: 20px;
      right: 20px;
      height: 40px;
      width: 40px;
      svg {
        margin-top: 10px;
      }
    }
    .ginner-container {
      .gclose {
        display: none;
      }
    }
  }
  .goverlay {
    background-color: $blue;
    opacity: .92;
  }

  &.glightbox-content {
    .gslide-inline {
      position: relative;
      .modalContent {
        .imageWrapper {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 40%;
          @include touch {
            width: 100%;
            height: 45%;
          }
          .featureImage {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center center;
          }
        }
        .contentArea {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 60%;
          display: flex;
          align-items: center; /* Vertical center alignment */
          justify-content: center; 
          @include touch {
            top: 45%;
            width: 100%;
          }
          div {
            padding: 0 40px;

            @include touch {
              padding: 30px 20px;
            }
            h4 {
              margin-bottom: 20px;
              color: $blue;
            }
            .textBlock {
              padding: 0 0 20px 0;
                
                p {
                    color: $grey;
                }
            }
          }
        }
      }
      .ginlined-content {
        padding: 0;
      }
    }

    .gclose.gbtn {
      background-color: transparent;
      opacity: 1;
      position: absolute;
      right: .625rem;
      top: .625rem;

      svg {
        path {
          fill: $blue;
        }
      }
    }
  }
}

#announcement-modal {
  z-index: 901;
  .modal-background {
    background-color: $blue;
  }
  .content {
    max-height: 80%;
    width: 65%;
    @include touch {
      background: #FFF;
      width: 80%;
      height: 80%;
    }
    .box {
      padding:0;
    }
    .columns {
      background: #FFF;
      height: 100%;
      .column {
        
        &.image {
          @include touch {
            height: 48%;
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
          }
          .imageWrapper {
            height: 100%;
          }
        }
      }
      
      .imageWrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        @include touch {
          width: 100%;
          height: 45%;
        }
        .featureImage {
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center center;
        }
      }
      h2 {
        color: $blue;
        font-family: $body;
        font-size: 30px;
        font-weight: 670;
        padding-bottom: 30px;
      }
      .column {
        &:last-child {
          padding: 40px;
          @include touch {
            height: 55%;
            position: absolute;
            bottom: 0;
          }
          p {
            padding-bottom: 20px;
          }
          .button {
            margin-bottom: 30px;
          }
        }
      }
    }
  }  
}

.leadershipModal {
  z-index: 901;
  .modal-background {
    background-color: $white;
    opacity: 1;
  }
  .modal-content{
    padding: 80px;
    width: 100%;
    @include touch {
      padding: 20px;
    }
    .columns {
      .column {
        padding: 0 40px;
        img {
          margin-bottom: 30px;
        }
        h1 {
          font-family: $body;
          font-size: 40px;
          font-weight: 800;
          margin-bottom: 20px;
        }
       h2 {
          color: $green;
          font-family: $body;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 40px;
        }
        p {
          padding-bottom: 20px;
        }
      }
    }
  }
  .modal-close {
    &::before {
      background-color: black;
    }
    &::after {
      background-color: black;
    }
  }
}
.body-scroll-lock {
  overflow-y: hidden;
}
#search-modalB {
  z-index: 901; 
  &.is-active {
    @extend .smooth;
  }
  .modal-content {
    background-color: transparent;
    box-shadow: none;

    label {
      color: #FFF;
      font-size: 2em;
      font-weight: bold;
      line-height: 2;
    }
    ::placeholder {
      color: $white;
    }
    input {
      background-color: transparent;
      border: solid 1px $green;
      border-radius: 0;
      color: $white;

      &:focus~.icon {
        color: $white;
      }
    }
    .ginlined-content {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
  .modal-background {
    background-color: $blue;
    opacity: .92;
  }
}

[id$=-modal] {
  display: none;
}

.modalCards {
  .partial.info {
    .modal-background {
      background-color: $blue;
      opacity: .92;
    }
    .modalContent {
      min-width: 60%;
      //min-height: 70%;
      //height: 80%;
        
        @include touch {
            min-height: 70%;
        }
      .imageWrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 40%;
        @include touch {
          position: relative;
          display: block;
          height: 50%;
          width: 100%;
        }
        @include handheld {
          position: absolute;
          padding: 1.25rem 1.25rem 0;
        }
        .featureImage {
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center center;
        }
      }
      .contentArea {
        //position: absolute;
        position: relative;
        top: 0;
        bottom: 0;
        right: 0;
        left: 40%;
        padding: 100px 0;
        width: 60%;
        display: flex;
        align-items: center; /* Vertical center alignment */
        justify-content: center; 
        @include touch {
          //top: 45%;
          left: 0;
          display: block;
          width: 100%;
          padding: 50px 0;
        }
        @include handheld {
          position: absolute;
          top: 45%;
        }
        div {
          padding: 0 40px;
          h4 {
            margin-bottom: 20px;
          }
          .textBlock {
            padding: 0 0 20px 0;
          }
        }
      }
    }
  }
}



#share-modal {
  z-index: 901;
  transition: opacity .5s ease-in-out;
  .modal-background {
    background-color: $blue;
    opacity: .92;
  }
  .column {
    background: #fff;
    h2 {
      margin-top: 8%;
      label {
        text-transform: none;
      }
    }
  
    .social {
      margin: 10% 0;
  
      li {
        margin: 0;
  
        &:nth-child(2) {
          margin: 0 1.5rem;
        }
  
        .orb {
          border: 2px solid $blue;
          color: $blue;
          font-size: 1.75rem;
          padding: .75rem;
          height: 60px;
          width: 60px;
          i {
            padding: 3px 1px 0 0;
          }
        }
      }
    }
  
    form {
      margin: 0 auto;
      width: 80%;
  
      input {
        border-color: $subtleBlue;
        color: $blue;
        width: calc(100% - 48px);
      }
  
      .icon {
        color: $subtleBlue;
        cursor: pointer;
        font-size: larger;
        height: 100%;
        pointer-events: auto;
      }
    }
  
    .tooltip {
      margin-top: 1rem;
      opacity: 0;
      transition: opacity .5s ease-in-out;
  
      &.show {
        opacity: 1;
      }
  
      p {
        background: $blue;
        color: $white;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        padding: 10px;
      }
    }
  }
}
