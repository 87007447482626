#search {
    .hero {
        height: 360px;
        min-height: 0;
        
        @include handheld {
            height: 280px;
        }
    }
    .searchBar {
        padding: 20px;
    }
    .utilityBar {
        margin-bottom: 60px;
        
        @include tablet {
            margin-bottom: 40px;
        }
        @include handheld {
            margin-bottom: 15px;
            border: none;
        }
    }
    .searchResults {
        .card {
            margin-bottom: 15px;
            background-color: $greyD;
            border-bottom: 2px solid $greyD;
            box-shadow: none;
            @extend .smooth;
            
            &:hover {
                border-bottom: 2px solid $green;
            }
            
            /*&:last-of-type {
                margin-bottom: 0;
            }*/
            
            .card-content {
                padding: 25px 25px 23px 25px;

                .title {
                    //font-family: $titlingStandard;
                    font-family: $body;
                    color: $blue;
                    font-size: 17px;
                    font-weight: 600;
                    line-height: 1.1em;
                    margin: 0;
                    a {
                        color: $blue;
                        line-height: 1.3em;
                    }
                }
                .subtitle {
                    font-size: 16px;
                    margin-top: 15px;
                    margin-bottom: 0;
                }
            }
            .card-footer {
                font-size: 13px;
                text-align: left;
                text-transform: uppercase;
                margin: 0;
                border: none;
                padding: 0;
                display: block;
                
                p {
                    font-size: 13px;
                    font-weight: 500;
                    padding: 0;
                    margin-top: 15px;
                    a {
                        color: $greyA;
                    }
                }
            }
        }
    }
}
.emptyResults {
    text-align: center;
    padding: 100px 0;
        
    i {
        color: $green;
        font-size: 28px;
    }
    p {
        @extend h4;
        font-size: 28px;
        margin: 20px auto 0 auto;
            
        @include screen {
            width: 60%;
        }
    }
}
.searchPagination {
    font-size: 14px;
    font-weight: 500;
    border-top: solid 1px $greyD;
    padding: 20px 0;
    margin-top: 60px;
    text-transform: uppercase;
    
    @include handheld {
        margin-top: 40px;
        padding: 10px 0 20px 0;
    }
       
    a {
        color: $grey;
        font-size: 14px;
        font-weight: 600;
        padding: 0 6px;
        
        @include touch {
            padding: 0 10px;
            font-size: 15px;
        }
    }
    .prev, .next {
        a {
            &:hover {
                color: $green;
            }
        }
    }
    .pages {
        a {
            padding: 0 6px;
            
            @include touch {
                padding: 0 10px;
            }
            
            &:hover {
                color: $orange;
            }
        }
        .current {
            color: $green;
            padding: 0 6px;
            
            @include touch {
                padding: 0 10px;
            }
        }
    }
}