header {
    background: transparent;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 900;
    
    &.hideNav {
        .secondary {
            display: none;
        }
        .mobile-nav {
            display: none;
        }
        .navWrapper {
            display: none;
        }
        .brand {
            @media (min-width: #{$small-desktop-width}) and (max-width: 1150px) {
                top: 25px;
            }
            @include tablet {
                top: 25px;
            }
        }
    }

    .noscriptAlert {
        background: $grey3;
        width: 100%;
        padding: 15px 10px;
        font-family: $body;
        color: $white;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
    }
    .brand {
        display: inline-block;
        top: 32px;
        margin-right: 32px;
        position: relative;
        
        @include small-desktop {
            margin-right: 25px;
        }
        @media (min-width: #{$small-desktop-width}) and (max-width: 1150px) {
            top: 72px;
        }
        @include tablet {
            top: 72px;
        }
        @include handheld {
            top: 25px;
            left: -7px;
        }
        
        img {
            width: 115px;
            height: auto;
        }
    }
    &.simpleHeader {
        padding-top: 25px;
        
        .brand {
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            display: block;
            
            @include small-desktop {
                margin: 0 auto;
            }
            @media (min-width: #{$small-desktop-width}) and (max-width: 1150px) {
                top: 0;
                margin: 0 auto;
            }
            @include tablet {
                top: 0;
                margin: 0 auto;
            }
            @include handheld {
                top: 0;
                left: 0;
                margin: 0 auto;
            }
            img {
                width: 90px;
                
                @include handheld {
                    width: 80px;
                }
            }
        }
    }
    .navWrapper {
        display: inline-block;
        position: absolute;
        top: 54px;
        left: 180px; //added to support edge case browers
        
        @media (min-width: #{$small-desktop-width}) and (max-width: 1150px) {
            top: 94px;
        }
        @include tablet {
            top: 94px;
        }
        @include touch {
            display: none;
        }
    }
    .secondary {
        position: absolute;
        top: 0;
        right: 0;
        
        @media (min-width: #{$small-desktop-width}) and (max-width: 1150px) {
            left: 0;
            width: 100%;
        }
        @include tablet {
            left: 0;
            width: 100%;
        }
        @include handheld {
            display: none;
        }
        
        .faster {
            animation: faster ease .8s;
        }
        @keyframes faster {
            0% {
                transform: translateX(500px)
            }
            100% {
                transform: translateX(0px)
            }
        }
        
        .fast {
            animation: fast ease 1s;
        }
        @keyframes fast {
            0% {
                transform: translateY(-300px)
            }
            100% {
                transform: translateX(0px)
            }
        }
        
        .utilityNav {
            background: $white;
            position: relative;
            padding: 8px;
            text-align: center;
            z-index: 30;
            
            @media (min-width: #{$small-desktop-width}) and (max-width: 1150px) {
                width: 100%;
                position: absolute;
                left: 0;
                text-align: left;
                z-index: 20;
            }
            @include tablet {
                width: 100%;
                position: absolute;
                left: 0;
                text-align: left;
                z-index: 20;
            }
            
            &:before {
                content: " ";
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                background: $white;
                transform-origin: bottom left;
                -ms-transform: skew(30deg, 0deg);
                -webkit-transform: skew(30deg, 0deg);
                transform: skew(30deg, 0deg);
                
                @media (min-width: #{$small-desktop-width}) and (max-width: 1150px) {
                    display: none;
                }
                @include tablet {
                    display: none;
                }
            }
            
            li {
                letter-spacing: $spaceMd;
                display: inline-block;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                margin: 0 6px;
                
                @include small-desktop {
                    font-size: 13px;
                }
                
                a {
                    color: $blue;
                    
                    &:hover {
                        color: $green;
                    }
                }
            }
        }
        .hideNavCta {
            display: none;
        }
        .alert {
            background: $red;
            position: relative;
            padding: 10px 10px 10px 6px;
            color: $white;
            font-family: $body;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: $spaceMd;
            text-transform: uppercase;
            text-align: center;
            display: inline-block;
            float: right;
            @extend .smooth;
            z-index: 20;
            
            @include small-desktop {
                font-size: 13px;
            }
            @media (min-width: #{$small-desktop-width}) and (max-width: 1150px) {
                width: 25%;
                position: absolute;
                right: 0;
                padding: 13px;
                font-size: 14px;
                z-index: 30;
            }
            @include tablet {
                width: 30%;
                position: absolute;
                right: 0;
                padding: 13px;
                z-index: 30;
            }
            
            &:before {
                content: " ";
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                background: $red;
                transform-origin: bottom left;
                -ms-transform: skew(30deg, 0deg);
                -webkit-transform: skew(30deg, 0deg);
                transform: skew(30deg, 0deg);
                @extend .smooth;
            }
            
            &:hover {
                background: $orange;
                
                &:before {
                    background: $orange;
                }
            }
        }
    }
}