#contact {
    .contactKbs {
        overflow-x: hidden;
        width: 100%;
        .columns {
            flex-direction: row-reverse;

            &:last-child {
                margin-bottom: 0;
            }
            .column {
                //padding: 0;
            }
        }
    }
    .quickContact {
        padding: 64px 0;
        
        .textBlock {
            margin-bottom: 64px;
        }
    }
    .tabBlocks {
        .chooseDepartment {
            font-size: 17px;
            color: $blue;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 10px;
            display: block;
        }
        .descriptor {
            margin: 0 0 20px 0;
            font-size: 17px;
        }
        .departments {
            margin-bottom: 40px;
            flex-direction: row;
            
            .column {
                padding-top: var(--columnGap);
                padding-bottom: var(--columnGap);
                @include handheld {
                    padding: .5rem .75rem; 
                }
            }
            
            .departmentCard {
                background: #E1E2E2;
                border-radius: $radius;
                @extend .smooth;
                text-align: center;
                padding: 60px 30px;
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                
                @include desktop {
                    padding: 50px 25px;
                }
                @include small-desktop {
                    padding: 25px 15px;
                }
                @include tablet {
                    padding: 30px 15px;
                }
                @include handheld {
                    padding: 15px;
                    display: block;
                    text-align: left;
                }
                
                .faIcon {
                    font-size: 35px;
                    display: block;
                    color: #B9B9B9;
                    margin-bottom: 22px;
                    position: relative;
                    z-index: 11;
                    
                    @include small-desktop {
                        font-size: 30px;
                    }
                    @include handheld {
                        float: left;
                        font-size: 22px;
                        margin-right: 20px;
                        margin-bottom: 0;
                    }
                }
                .headline {
                    font-size: 18px;
                    color: $blue;
                    font-weight: 600;
                    display: block;
                    line-height: 1.3em;
                    position: relative;
                    z-index: 10;
                    
                    @include desktop {
                        font-size: 17px;
                    }
                    @include small-desktop {
                        font-size: 15px;
                    }
                    @include handheld {
                        float: left;
                        max-width: 80%;
                        font-size: 15px;
                        top: 2px;
                    }
                }
                .overlay {
                    background: $kbsWarm;
                    opacity: 0;
                    border-radius: $radius;
                }
                
                &:hover {
                    //background: $kbsWarm;
                    
                    .overlay {
                        opacity: 1;
                    }
                    .faIcon {
                        color: $white;
                    }
                    .headline {
                        color: $white;
                    }
                }
                &.active {
                    background: $kbsWarm;
                    
                    .faIcon {
                        color: $white;
                    }
                    .headline {
                        color: $white;
                    }
                }
            }
        }
        .tabContent {
            //.hubSpotWrapper {}
            .fallbackMessage {
                text-align: center;
                padding: 50px 80px;
                border: 1px solid $greyD;
                
                @include tablet {
                    padding: 50px 60px;
                }
                @include handheld {
                    padding: 40px;
                }
                
                h3 {
                    font-family: $titlingBold;
                    font-size: 22px;
                    margin-bottom: 15px;
                }
                .button {
                    margin-top: 30px;
                }
            }
        }
    }
    .sidebar {
        width: 100%;
        padding: 64px 32px;
        
        .sidebarContent {
            max-width: 400px;
            
            @include touch {
                margin: 0 auto;
                max-width: 768px;
            }
            @include handheld {
                max-width: 100%;
            }
        }
        
        .contactInfo li span {
            max-width: 80%;
            
            @include small-mobile {
                max-width: 100%;
            }
        }
        h3 {
            margin-bottom: 30px;
        }
        img {
            margin-bottom: 30px;
        }
        .social {
            margin: 30px 0;
        }
        
        .linkBuilder {
            li {
                color: $white;
                font-weight: 500;
                padding: 20px 0;
                border-top: 2px solid $green;
                
                a {
                    color: $white;
                    
                    &:hover {
                        color: $green;
                    }
                }
            }
            @include handheld {
                text-align: center;
            }
        }
    }
}