.covidBanner {
    width: 80%;
    margin: 0 auto;
    padding: 50px 40px 40px 15px;
    
    @include small-desktop {
        width: 85%;
    }
    @include tablet {
        width: 85%;
        padding: 40px;
    }
    @include handheld {
        width: 100%;
        padding: 40px;
    }
    
    .is-flex {
        flex-direction: column;
        justify-content: center;
    }
    h4 {
        font-size: 40px;
        font-family: $titlingBold;
        text-align: center;
    }
    h5 {
        color: $green;
        font-size: 17px;
        letter-spacing: $spaceMd;
        text-align: center;
        text-transform: uppercase;
    }
    .button {
        margin-top: 30px;
        
        &.hasIcon {
            i {
                color: $yellow;
            }
        }
    }
    
    &.bg-darkBlue {
        h4 {
            color: $white;
        }
        p {
            color: $white;
        }
        .button {
            &.hasIcon {
                color: $white!important;
            }
        }
    }
    &.bg-white {
        h4 {
            color: $blue;
        }
        p {
            color: $grey;
        }
        .button {
            &.hasIcon {
                color: $blue!important;
            }
        }
    }
    &.bg-grey {
        h4 {
            color: $blue;
        }
        p {
            color: $grey;
        }
        .button {
            &.hasIcon {
                color: $blue!important;
            }
        }
    }
    &.bg-lightGreen {
        h4 {
            color: $blue;
        }
        p {
            color: $grey;
        }
        .button {
            &.hasIcon {
                color: $blue!important;
            }
        }
    }
}