.pullQuote {
    margin: 0 auto;
    &.center {
        width: 80%;
        
        @include small-desktop {
            width: 85%;
        }
        @include handheld {
            width: 90%;
        }
    }
    &.left {
        width: 80%;
        
        @include small-desktop {
            width: 85%;
        }
        @include handheld {
            width: 90%;
        }
    }
    .quotation {
        color: $green;
        font-family: $titlingStandard;
        font-size: 75px;
        line-height: 1em;
    }
    blockquote {
        font-family: $titlingStandard;
        font-size: 22px;
        letter-spacing: spaceSm;
        position: relative;
        top: -30px;
        
        @include handheld {
            font-size: 19px;
        }
    }
    .credit {
        .imageWrapper {
            width: 80px;
            height: 80px;
            @extend .backgroundSettings;
            border-radius: $round;
            display: inline-block;
            margin-right: 15px;
            
            @include handheld {
                display: block;
                margin: 0 auto 15px auto;
            }
        }
        ul {
            display: inline-block;
            text-align: left;
            position: relative;
            top: -25px;
            
            @include handheld {
                display: block;
                text-align: center;
                top: 0;
            }
            
            &.noImg {
                top: 0;
                text-align: center;
            }
            .name {
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 600;
                letter-spacing: spaceSm;
            }
            .job {
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: spaceSm;
            }   
        }
    }
    
}