#sitemap {
    padding-bottom: 60px;
    h1 {
        font-size: 36px;
        margin: 60px 0 30px 0;
    }
    h2 {
        font-size: 30px;
        margin: 40px 0 20px 0;
    }
    a {
        color: $blue;
        
        &:hover {
            color: $green;
        }
    }
}