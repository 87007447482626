.linkBuilder {
    .linkBlock {
        width: 100%;
        background: $white;
        border: 1px solid $greyD;
        font-size: 17px;
        font-weight: 600;
        line-height: 1.2em;
        color: $grey;
        position: relative;
        @extend .smooth;
        
        @include handheld {
            font-size: 16px;
        }
        
        &:hover {
            border-color: $yellow;
            i {
                color: $green;
                border-color: $yellow;
            }
        }
            
        span {
            padding: 15px;
            width: 80%;
            display: inline-block;
        }
        i {
            border-left: 1px solid $greyD;
            padding: 8px 20px;
            display: inline-block;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            @extend .smooth;
        }
        
        &.noLink {
            i {
                display: none;
            }
            &:hover {
                border-color: $greyD;
            }
        }
    }
}