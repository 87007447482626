/* BASE */
/* purgecss start ignore */
// @import 'base/_webfonts.scss';
@import 'base/_variables.scss';
@import 'base/_mixins.scss';
@import 'base/_helpers.scss';
@import 'base/_base.scss';
@import 'base/_typography.scss';
@import 'base/_buttons.scss';
@import 'base/_inputs.scss';
/* purgecss end ignore */

/* GLight */
@import '~glightbox/dist/css/glightbox.min.css';

/* COMPONENTS */
/* purgecss start ignore */
@import './components/lazyBg';
@import 'components/_header.scss';
@import 'components/_nav.scss';
@import 'components/_hero.scss';
@import 'components/_footer.scss';
@import 'components/_breadcrumb.scss';
@import 'components/_searchBar.scss';
@import 'components/_utilityBar.scss';
@import 'components/_modals.scss';
@import 'components/acsb.scss';
@import 'components/customAOS.scss';

/* BLOCKS */
@import 'blocks/_cta.scss';
@import 'blocks/_cards.scss';
@import 'blocks/_accentHero.scss';
@import 'blocks/_covidBanner.scss';
@import 'blocks/_brandBanner.scss';
@import 'blocks/_spacer.scss';
@import 'blocks/_textBlock.scss';
@import 'blocks/_pullQuote.scss';
@import 'blocks/_clients.scss';
@import 'blocks/_splitView.scss';
@import 'blocks/_image.scss';
@import 'blocks/_features.scss';
@import 'blocks/_sidebarSection.scss';
@import 'blocks/_history.scss';
@import 'blocks/_accordion.scss';
@import 'blocks/_gallery.scss';
@import 'blocks/_statsHero.scss';
@import 'blocks/_linkBuilder.scss';
@import 'blocks/_video.scss';
@import 'blocks/_videoGroup.scss';
@import 'blocks/_tabSlider.scss';
@import 'blocks/_associations.scss';
@import 'blocks/_locations.scss';
@import 'blocks/_taskNav.scss';
@import 'blocks/_listGroup.scss';

/* PAGES */
@import 'pages/_home.scss';
@import 'pages/_contact.scss';
@import 'pages/_insights.scss';
@import 'pages/_search.scss';
@import 'pages/_contractors.scss';
@import 'pages/_error.scss';
@import 'pages/_job_search.scss';
@import 'pages/_sitemap.scss';
@import 'pages/_canada.scss';

/* IFS */
@import 'ifs/_ifs.scss';
@import 'ifs/_ifsHeader.scss';
@import 'ifs/_ifsHero.scss';
@import 'ifs/_ifsFooter.scss';
@import 'ifs/_ifsBlocks.scss';
@import 'ifs/_ifsContact.scss';
/* purgecss end ignore */
