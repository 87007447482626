#tabSlider {
    .container {
        nav {
            a {
                background: transparent;
                color: $blue;
                display: inline-block;
                font-size: .9em;
                padding: 10px 20px;
                text-transform: uppercase;
                font-weight: 600;
                
                &.active {
                    background: $blue;
                    color: $white;
                }
                
                @include touch {
                    display: block;
                    margin-bottom: 10px;
                }
            }
        }
        .slide {
            margin: 15px 0 0 0;
            box-shadow: $shadow;
            position: relative;
            
            .contentArea {
                width: 60%;
                padding: 50px;
                
                @include touch {
                    width: 100%;
                    padding: 30px;
                }
                &.fullWidth {
                    width: 100%;
                    padding: 50px;
                    
                    @include touch {
                        padding: 30px;
                    }
                }

                h3 {
                    font-size: 32px;
                    margin-bottom: 30px;
                }
                .textBlock {
                    margin-bottom: 20px;
                }
                
                //Theming
                h1, h2, h3, h4, h5, h6 {
                    color: $blue;
                }
                p, li {
                    color: $grey;
                }
                .button {
                    &.hasIcon {
                        color: $blue!important;
                    }
                }
            }
            .imageWrapper {
                @extend .backgroundSettings;
                width: 30%;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                
                @include touch {
                    position: relative;
                    display: block;
                    height: 300px;
                    width: 100%;
                }
            }
        }
    }
}
.bg-darkBlue {
    #tabSlider {
        .container {
            nav {
                a {
                    background: transparent;
                    color: $white;

                    &.active {
                        background: $greyF;
                        color: $blue;
                    }
                }
            }
        }
    }
}